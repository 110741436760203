<template>
  <b-modal
      id="modal-add-edit-desk"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ isEdit ? $t("MANAGEMENT.EDIT_DESK_TITLE") : $t("MANAGEMENT.ADD_DESK_TITLE") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label-for="name-input"
          :invalid-feedback="$t('MANAGEMENT.NAME_DESK_INVALID_FEEDBACK')"
          :state="nameState">
        <template slot="label">{{ $t("MANAGEMENT.NAME_DESK_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-input
            id="name-input"
            :placeholder="$t('MANAGEMENT.NAME_DESK_INVALID_FEEDBACK')"
            v-model="form.name"
            :state="nameState"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          v-if="isEdit"
          label-for="status-input"
          invalid-feedback="$t('MANAGEMENT.DESK_STATUS_INVALID_FEEDBACK')"
          :state="statusState">
        <template slot="label">{{ $t("MANAGEMENT.DESK_STATUS_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="status-input"
            v-model="form.status"
            :state="statusState"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="item.value" v-for="item in statusesList" :key="item.value">{{ item.name }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
          v-if="isEdit"
          label-for="accepted-groups-input">
        <template slot="label">{{ $t("MANAGEMENT.RESOURCE_ACCESS_TITLE") }}</template>
        <DropdownSearch
            :options="groupsList"
            v-on:selectedGroup="onSearchGroups"
            v-on:filter="getDropdownGroupsValues"
            :disabled="false"
            name="dropdownGroupSearch"
            :placeholder="$t('MANAGEMENT.RESOURCE_ACCESS_PLACEHOLDER')">
        </DropdownSearch>
        <div class="col-12 p-0">
          <div class="selected-groups mr-4">
            <p class="mt-2 mb-0 p-0">
              {{ addedResourceGroups.length > 0 ? $t("MANAGEMENT.RESOURCE_ACCESS_LIST_TITLE") :
                $t("MANAGEMENT.NO_RESOURCE_YET_TITLE") }}</p>
            <div class="row selected-groups-item py-3"
                 v-for="(group, i) in addedResourceGroups" :key="i">
              <div class="col-11">
                <h6 class="text-left font-weight-bolder text-dark pt-2 m-0">{{ group.name }}</h6>
              </div>
              <div class="col-1 mb-1">
                <span class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      @click="onDeleteGroup(group)"><Trash/></span>
              </div>
            </div>
          </div>
        </div>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ isEdit ? $t("MANAGEMENT.SAVE_BUTTON") : $t("MANAGEMENT.ADD_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_GROUPS_LIST } from "@/core/services/store/management.module";
import {
  GET_DESKS_LIST,
  POST_NEW_DESK,
  PUT_DESK,
  ACTIVATE_DESK,
  DEACTIVATE_DESK, ADD_ASSIGN_GROUP, REMOVE_ASSIGN_GROUP, SET_RESOURCE_ASSIGNED_GROUP, GET_RESOURCE_ASSIGNED_GROUP
} from "@/core/services/store/management/resources.module";

import DropdownSearch from "@/view/layout/extras/DropdownSearch";
import Trash from "@/view/layout/svg/Trash";

export default {
  name: "AddEditDeskModal",
  components: {
    DropdownSearch,
    Trash
  },
  props: {
    onPage: {
      type: String
    },
    form: {
      type: Object,
      default: ()=> {
        return {
          name: ''
        }
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nameState: null,
      statusState: null,
      statusesList: [
        { name: this.$t("MANAGEMENT.AV_STATUS_TYPE"), value: "active" },
        { name: this.$t("MANAGEMENT.UN_STATUS_TYPE"), value: "inactive"}
      ]
    }
  },
  computed: {
    ...mapGetters([
        "groupsList",
        "addedResourceGroups"
    ]),
  },
  methods: {
    onSearchGroups(searchQuery) {
      this.$store.dispatch(ADD_ASSIGN_GROUP, { id: this.form.id, body: { groupId: searchQuery.id } })
          .then(() => this.$store.dispatch(GET_RESOURCE_ASSIGNED_GROUP, { id: this.form.id }));
    },
    getDropdownGroupsValues(searchQuery) {
      this.$store.dispatch(GET_GROUPS_LIST, { name: searchQuery });
    },
    onDeleteGroup(group) {
      this.$store.dispatch(REMOVE_ASSIGN_GROUP, { id: this.form.id, body: { groupId: group.id } })
          .then(() => this.$store.dispatch(GET_RESOURCE_ASSIGNED_GROUP, { id: this.form.id }))
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      if (this.isEdit) this.form.status !== null ? this.statusState = true : this.statusState = false;
      return valid;
    },
    resetModal() {
      if (this.onPage === 'resources') {
        let emptyForm = {
          name: ''
        };
        this.$emit('changeDeskForm', emptyForm);
      }
      this.$emit('changeIsEdit', false);
      this.nameState = null;
      this.statusState = null;
      this.$store.commit(SET_RESOURCE_ASSIGNED_GROUP, []);
      this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id })
      this.$bvModal.hide('modal-add-edit-desk');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.isEdit) {
        let id = this.form.id;
        let status = this.form.status;
        let form = {
          name: this.form.name
        }
        this.$store.dispatch(PUT_DESK, { form, id: this.form.id })
            .then(() => {
              if (status === 'active') this.$store.dispatch(ACTIVATE_DESK, { id })
              else this.$store.dispatch(DEACTIVATE_DESK, { id })
            });
      } else this.$store.dispatch(POST_NEW_DESK, { id: this.$route.params.id, body: this.form });
      // this.$nextTick(() => {
        setTimeout(() => this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id }), 500)
        this.$bvModal.hide('modal-add-edit-desk');
      // })
    },
  }
}
</script>

<style scoped>

</style>

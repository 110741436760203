<template>
  <!--begin::Resources-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header card-header-tabs-line flex-wrap pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.RESOURCES_TITLE") }}</h3>
      </div>
      <div class="card-toolbar">
        <b-nav tabs class="nav nav-tabs nav-bold nav-tabs-line">
          <b-nav-item to="#desk" :active="$route.hash === '#desk' || $route.hash === ''">
            {{ $t("MANAGEMENT.RESOURCES_DESKS_TITLE") }}
          </b-nav-item>
          <b-nav-item to="#meeting-room" :active="$route.hash === '#meeting-room'">
            {{ $t("MANAGEMENT.RESOURCES_MEET_ROOMS_TITLE") }}
          </b-nav-item>
        </b-nav>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isLoading" class="card-body">
      <div class="tab-content">
        <div :class="['tab-pane', { 'active': $route.hash === '#desk' || $route.hash === '' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-8 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.RESOURCES_DESKS_TITLE") }}
              <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.RESOURCES_DESKS_TITLE_DESC") }}</span>
            </h5>
            <!--begin::Button-->
            <div class="col-md-4">
              <b-button v-if="isCanEdit"
                        v-b-modal.modal-add-edit-desk
                        class="float-right btn bg-light-primary font-weight-bolder font-size-sm">
                <i class="fas fa-plus icon-md"></i>{{ $t("MANAGEMENT.ADD_DESK_BUTTON") }}
              </b-button>
            </div>
            <!--end::Button-->
          </div>
          <!--begin::Dummy-->
          <template v-if="deskList.items.length === 0 && !notFound">
            <div class="justify-content-center row mt-10">
              <div class="col-auto d-flex">
                <DeskImage/>
              </div>
            </div>
            <div class="justify-content-center row mb-10">
              <h5 class="col-auto font-weight-bolder text-dark my-5 text-center">{{ $t("MANAGEMENT.NO_DESKS_YET_TITLE") }}
                <span v-if="isCanEdit" class="d-block font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.NO_DESKS_YET_DESC") }}</span>
                <b-button v-if="isCanEdit"
                          v-b-modal.modal-add-edit-desk
                          class="btn bg-primary text-light font-weight-bolder my-4">{{ $t("MANAGEMENT.ADD_DESK_BUTTON") }}
                </b-button>
              </h5>
            </div>
          </template>
          <!--end::Dummy-->
          <template v-if="deskList.items.length > 0 || notFound">
            <!--begin: Search Form-->
            <div class="mb-7">
              <div class="row align-items-center">
                <div class="col-lg-10 col-xl-10">
                  <div class="row align-items-center">
                    <div class="col-md-3 my-2 my-md-0">
                      <div class="input-icon">
                        <input type="text" class="form-control"
                               v-model="filter_desk_name"
                               :placeholder="$t('SELECT.SEARCH')" />
                        <span><i class="flaticon-search text-muted"></i></span>
                      </div>
                    </div>
                    <div class="col-md-3 my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <label class="mr-3 mb-0 d-none d-md-block">{{ $t("TITLE.STATUS_TITLE") }}</label>
                        <treeselect v-model="filter_desk_statuses"
                                    :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                                    :multiple="true"
                                    :normalizer="normalizer"
                                    :limit="1"
                                    :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                                    :options="statusesList">
                          <div slot="value-label" slot-scope="{ node }">{{ node.raw.name.slice(0, 5) }}</div>
                        </treeselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Search Form-->
            <!--begin::Table-->
            <div class="table-responsive">
              <b-table
                  id="desks-table"
                  class="table-vertical-center"
                  show-empty
                  :items="deskList.items"
                  :fields="fields"
                  :per-page="0"
                  :current-page="deskList.pagination.page">
                <template #empty="scope">
                  <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
                </template>
                <template #head(name)="data">
                  <span @click="onChangeDirection('desk')"
                        class="pl-0 font-weight-bolder cursor-pointer">{{ data.label }}</span>
                </template>
                <template #head()="data">
                  <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
                </template>
                <template #head(actions)="data">
                  <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
                </template>
                <template #cell(name)="data">
                  <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
                </template>
                <template #cell(status)="data">
                  <span :class="data.item.status === 'active' ? 'label-success' : 'label-danger'"
                        class="label label-lg label-inline  m-1">{{ data.item.status === 'active' ? $t("MANAGEMENT.AV_STATUS_TYPE") : $t("MANAGEMENT.UN_STATUS_TYPE") }}</span>
                </template>
                <template #cell(actions)="data">
                  <span class="float-right">
                    <b-button v-if="isCanEdit"
                              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                              @click="onEditResource(data.item, 'desk')"
                              v-b-modal.modal-add-edit-desk
                              v-b-tooltip.hover.v-dark="$t('MANAGEMENT.EDIT_TOOLTIP_TEXT')">
                      <Edit/>
                    </b-button>
                    <b-button v-if="isCanEdit"
                              class="btn btn-icon btn-light btn-hover-primary btn-sm"
                              @click="onDeleteResource(data.item, 'desk')"
                              v-b-modal.modal-delete-desk
                              v-b-tooltip.hover.v-dark="$t('MANAGEMENT.DELETE_TOOLTIP_TEXT')">
                      <Trash/>
                    </b-button>
                  </span>
                </template>
              </b-table>
            </div>
            <!--end::Table-->
            <!--begin: Pagination-->
            <Pagination
                name="desks-table"
                :pagination="deskList.pagination"
                @changePageLimit="changePageLimitDesk">
            </Pagination>
            <!--end: Pagination-->
          </template>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#meeting-room' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-8 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.RESOURCES_MEET_ROOMS_TITLE") }}
              <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.RESOURCES_MEET_ROOMS_TITLE_DESC") }}</span>
            </h5>
            <!--begin::Button-->
            <div class="col-md-4">
              <b-button v-if="isCanEdit"
                        v-b-modal.modal-add-edit-meeting-room
                        class="float-right btn bg-light-primary font-weight-bolder font-size-sm">
                <i class="fas fa-plus icon-md"></i>{{ $t("MANAGEMENT.ADD_MEET_ROOM_BUTTON") }}
              </b-button>
            </div>
            <!--end::Button-->
          </div>
          <!--begin::Dummy-->
          <template v-if="meetingRoomsList.items.length === 0 && !notFound">
            <div class="justify-content-center row mt-10">
              <div class="col-auto d-flex">
                <MeetingRoomImage/>
              </div>
            </div>
            <div class="justify-content-center row mb-10">
              <h5 class="col-auto font-weight-bolder text-dark my-5 text-center">{{ $t("MANAGEMENT.NO_MEET_ROOMS_YET_TITLE") }}
                <span v-if="isCanEdit" class="d-block font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.NO_MEET_ROOMS_YET_DESC") }}</span>
                <b-button v-if="isCanEdit"
                          v-b-modal.modal-add-edit-meeting-room
                          class="btn bg-primary text-light font-weight-bolder my-4">{{ $t("MANAGEMENT.ADD_MEET_ROOM_BUTTON") }}
                </b-button>
              </h5>
            </div>
          </template>
          <!--end::Dummy-->
          <template v-if="meetingRoomsList.items.length > 0 || notFound">
            <!--begin: Search Form-->
            <div class="mb-7">
              <div class="row align-items-center">
                <div class="col-lg-10 col-xl-10">
                  <div class="row align-items-center">
                    <div class="col-md-3 my-2 my-md-0">
                      <div class="input-icon">
                        <input type="text" class="form-control" v-model="filter_meeting_name" :placeholder="$t('SELECT.SEARCH')" />
                        <span><i class="flaticon-search text-muted"></i></span>
                      </div>
                    </div>
                    <div class="col-md-3 my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <label class="mr-3 mb-0 d-none d-md-block">{{ $t("TITLE.STATUS_TITLE") }}</label>
                        <treeselect v-model="filter_meeting_statuses"
                                    :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                                    :multiple="true"
                                    :normalizer="normalizer"
                                    :limit="1"
                                    :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                                    :options="statusesList">
                          <div slot="value-label" slot-scope="{ node }">{{ node.raw.name.slice(0, 5) }}</div>
                        </treeselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Search Form-->
            <!--begin::Table-->
            <div class="table-responsive">
              <b-table
                  id="meetings-table"
                  class="table-vertical-center"
                  show-empty
                  :items="meetingRoomsList.items"
                  :fields="fields"
                  :per-page="0"
                  :current-page="meetingRoomsList.pagination.page">
                <template #empty="scope">
                  <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
                </template>
                <template #head(name)="data">
                  <span @click="onChangeDirection('meeting')"
                        class="pl-0 font-weight-bolder cursor-pointer">{{ data.label }}</span>
                </template>
                <template #head()="data">
                  <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
                </template>
                <template #head(actions)="data">
                  <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
                </template>
                <template #cell(name)="data">
                  <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
                </template>
                <template #cell(status)="data">
                  <span :class="data.item.status === 'active' ? 'label-success' : 'label-danger'"
                        class="label label-lg label-inline  m-1">{{ data.item.status === 'active' ? $t("MANAGEMENT.AV_STATUS_TYPE") : $t("MANAGEMENT.UN_STATUS_TYPE") }}</span>
                </template>
                <template #cell(actions)="data">
                  <span class="float-right">
                    <b-button v-if="isCanEdit"
                              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                              @click="onEditResource(data.item, 'meeting')"
                              v-b-modal.modal-add-edit-meeting-room
                              v-b-tooltip.hover.v-dark="$t('MANAGEMENT.EDIT_TOOLTIP_TEXT')">
                      <Edit/>
                    </b-button>
                    <b-button v-if="isCanEdit"
                              class="btn btn-icon btn-light btn-hover-primary btn-sm"
                              @click="onDeleteResource(data.item, 'meeting')"
                              v-b-modal.modal-delete-meeting-room
                              v-b-tooltip.hover.v-dark="$t('MANAGEMENT.DELETE_TOOLTIP_TEXT')">
                      <Trash/>
                    </b-button>
                  </span>
                </template>
              </b-table>
            </div>
            <!--end::Table-->
            <!--begin: Pagination-->
            <Pagination
                name="meetings-table"
                :pagination="meetingRoomsList.pagination"
                @changePageLimit="changePageLimitMeeting">
            </Pagination>
            <!--end: Pagination-->
          </template>
        </div>
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Add Edit Desk Modal-->
    <AddEditDeskModal
        :onPage="'resources'"
        :form="formDesk"
        :isEdit="isEdit"
        @changeIsEdit="toggleIsEdit"
        @changeDeskForm="clearDeskForm"/>
    <!--end::Add Edit Desk Modal-->
    <!--begin::Delete Desk Modal-->
    <b-modal
        id="modal-delete-desk"
        centered>
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_DESK_TITLE") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_DESK_DESC") }}</h5>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="danger"
                @click="handleDelete">
              {{ $t("MANAGEMENT.DELETE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--end::Delete Desk Modal-->
    <!--begin::Add Edit Meeting Room Modal-->
    <AddEditMeetingRoomModal
        :onPage="'resources'"
        :form="formMeeting"
        :isEdit="isEdit"
        @changeIsEdit="toggleIsEdit"
        @changeMeetingRoomForm="clearMeetingRoomForm"/>
    <!--end::Add Edit Meeting Room Modal-->
    <!--begin::Delete Meeting Room Modal-->
    <b-modal
        id="modal-delete-meeting-room"
        centered>
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_MEET_ROOM_TITLE") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_MEET_ROOM_DESC") }}</h5>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="danger"
                @click="handleDelete">
              {{ $t("MANAGEMENT.DELETE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--end::Delete Meeting Room Modal-->
  </div>
  <!--end::Resources-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_FLOOR } from "@/core/services/store/management.module";
import {
  DELETE_DESK,
  GET_DESKS_LIST,
  DELETE_MEETING_ROOM,
  GET_MEETING_ROOMS_LIST,
  GET_RESOURCE_ASSIGNED_GROUP
} from "@/core/services/store/management/resources.module";

import MeetingRoomImage from "@/view/layout/svg/MeetingRoomImage";
import DeskImage from "@/view/layout/svg/DeskImage";
import Edit from "@/view/layout/svg/Edit";
import Trash from "@/view/layout/svg/Trash";
import Pagination from "@/view/layout/pagination/Pagination";
import AddEditDeskModal from "@/view/layout/modal/AddEditDeskModal";
import AddEditMeetingRoomModal from "@/view/layout/modal/AddEditMeetingRoomModal";

export default {
  name: "FloorResources",
  components: {
    MeetingRoomImage,
    DeskImage,
    Edit,
    Trash,
    Pagination,
    AddEditDeskModal,
    AddEditMeetingRoomModal
  },
  mounted() {
    this.$store.dispatch(GET_FLOOR, this.$route.params.id).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MANAGEMENT.OFFICES_TITLE"), route: "/management/offices" },
        { title: `${this.floor.office.name}`, route: `/management/offices/office/${this.floor.office.id}` },
        { title: `${this.floor.name}`}
      ]);
      this.isLoading = true;
      localStorage.setItem("pageNumber", JSON.stringify({ page: 1, limit: 20 }));
    });
    this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id });
    this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id });
  },
  data() {
    return {
      normalizer(node) {
        return {
          id: node.value,
          label: node.name,
        }
      },
      notFound: false,
      sortBy: true,
      filter_desk_name: '',
      filter_desk_statuses: null,
      filters_desk: {
        name: '',
        statuses: null
      },
      filter_meeting_name: '',
      filter_meeting_statuses: null,
      filters_meeting: {
        name: '',
        statuses: null
      },
      isEdit: false,
      isLoading: false,
      formDesk: {
        name: ''
      },
      formMeeting: {
        name: '',
        properties: {
          area: null,
          amountOfSeats: null
        }
      },
      statusesList: [
        { name: this.$t("MANAGEMENT.AV_STATUS_TYPE"), value: "active" },
        { name: this.$t("MANAGEMENT.UN_STATUS_TYPE"), value: "inactive"}
      ],
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.NAME_TABLE_TITLE") },
        { key: 'status', label: this.$t("MANAGEMENT.STATUS_TABLE_TITLE") },
        { key: 'actions', label: this.$t("MANAGEMENT.ACTIONS_TABLE_TITLE") }
      ],
      deleteResource: null
    }
  },
  watch: {
    filter_desk_name(val) {
      this.notFound = val.length > 0;
      this.filters_desk.name = val;
      this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id, params: this.filters_desk });
    },
    filter_desk_statuses(val) {
      this.notFound = val.length > 0;
      if (val.length > 0) {
        this.filters_desk.statuses = val.join(',');
        this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id, params: this.filters_desk });
      } else {
        this.filters_desk.statuses = null;
        this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id });
      }
    },
    filter_meeting_name(val) {
      this.notFound = val.length > 0;
      this.filters_meeting.name = val;
      this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id, params: this.filters_meeting });
    },
    filter_meeting_statuses(val) {
      this.notFound = val.length > 0;
      if (val.length > 0) {
        this.filters_meeting.statuses = val.join(',');
        this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id, params: this.filters_meeting });
      } else {
        this.filters_meeting.statuses = null;
        this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id });
      }
    }
  },
  computed: {
    ...mapGetters([
      "floor",
      "deskList",
      "meetingRoomsList"
    ]),
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]));
        return role !== undefined;
      }
    },
  },
  methods: {
    onChangeDirection(name) {
      this.sortBy = !this.sortBy;
      if (name === 'desk') {
        this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id, params: { direction: this.sortBy ? 'asc' : 'desc' } });
      } else if (name === 'meeting') {
        this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id, params: { direction: this.sortBy ? 'asc' : 'desc' } });
      }
    },
    changePageLimitDesk(val) {
      localStorage.setItem("pageNumber", JSON.stringify(val));
      this.$store.dispatch(GET_DESKS_LIST, { id: this.$route.params.id, params: val });
    },
    changePageLimitMeeting(val) {
      localStorage.setItem("pageNumber", JSON.stringify(val));
      this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id, params: val });
    },
    toggleIsEdit(variable) {
      this.isEdit = variable;
    },
    clearDeskForm(variable) {
      this.formDesk = variable;
    },
    clearMeetingRoomForm(variable) {
      this.formMeeting = variable;
    },
    onEditResource(item, type) {
      this.isEdit = true;
      if (type === 'desk') this.formDesk = item;
      else this.formMeeting = item;
      this.$store.dispatch(GET_RESOURCE_ASSIGNED_GROUP, { id: item.id })
    },
    onDeleteResource(item, type) {
      this.deleteResource = {
        id: item.id,
        type
      }
    },
    resetModal() {
      this.deleteResource = null;
      this.$bvModal.hide('modal-delete-desk');
      this.$bvModal.hide('modal-delete-meeting-room');
    },
    handleDelete() {
      if (this.deleteResource.type === 'desk') {
        this.$store.dispatch(DELETE_DESK, { id: this.deleteResource.id })
            .then(() => {
              this.deleteResource = null;
              let pageData = JSON.parse(localStorage.getItem("pageNumber"));
              this.changePageLimitDesk(pageData);
            });
      } else {
        this.$store.dispatch(DELETE_MEETING_ROOM, { id: this.deleteResource.id })
            .then(() => {
              this.deleteResource = null;
              let pageData = JSON.parse(localStorage.getItem("pageNumber"));
              this.changePageLimitMeeting(pageData);
            })
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-delete-desk');
        this.$bvModal.hide('modal-delete-meeting-room');
      })
    }
  }
}
</script>

<style scoped>

</style>

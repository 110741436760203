<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="512.604" height="250" viewBox="0 0 512.604 250">
    <defs>
      <clipPath id="clip-path">
        <path id="Контур_3577" data-name="Контур 3577" d="M118.716-1161.388c.023.018,447.239-.055,447.239-.055,14.723-31.405,14.7-46.749,16.375-64.751,9.25-99.495-65.689-114.791-87.642-118.94-67.221-12.7-36.627-75.558-211.794-63.765-16.055,1.081-53.909,9.807-66.954,15.46-9.15,3.962-43.623,21.69-53.738,51.678a53.9,53.9,0,0,0-.74,32.01c11.4,41.969-18.321,39.556-40.038,59.557-28.986,26.691-25.956,70.343-2.708,88.805" transform="translate(-100.481 1410.365)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Контур_3576" data-name="Контур 3576" d="M-142-1360.083H425.583V-1715H-142Z" transform="translate(142 1715)" fill="none"/>
      </clipPath>
      <clipPath id="clip-path-3">
        <path id="Контур_3580" data-name="Контур 3580" d="M1690.625-207.53h.06a.644.644,0,0,0,.644-.66l-.976-42.285-2.873-.335,2.451,42.624a.694.694,0,0,0,.693.656" transform="translate(-1687.48 250.81)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-5">
        <path id="Контур_3583" data-name="Контур 3583" d="M1785.045-207.53h.06a.644.644,0,0,0,.644-.66l-.976-42.285-2.873-.335,2.45,42.624a.7.7,0,0,0,.7.656" transform="translate(-1781.9 250.81)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-7">
        <path id="Контур_3586" data-name="Контур 3586" d="M1535.813-207.53h-.06a.645.645,0,0,1-.644-.66l.975-42.285,2.875-.335-2.451,42.624a.7.7,0,0,1-.7.656" transform="translate(-1535.109 250.81)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-9">
        <path id="Контур_3589" data-name="Контур 3589" d="M1627.9-440.913v-28.975a9.231,9.231,0,0,0-9.23-9.232h-20.878a9.232,9.232,0,0,0-9.232,9.232V-439.8l39.341-1.109" transform="translate(-1588.56 479.12)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-11">
        <path id="Контур_3592" data-name="Контур 3592" d="M1567.48-259.136h40.309a2.77,2.77,0,0,0,2.771-2.771v-1.8h-43.08v4.574" transform="translate(-1567.48 263.71)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-13">
        <path id="Контур_3595" data-name="Контур 3595" d="M1530.739-259.136h30.5a2.049,2.049,0,0,0,2.05-2.049v-.475a2.05,2.05,0,0,0-2.05-2.05h-30.5a2.05,2.05,0,0,0-2.049,2.05v.475a2.048,2.048,0,0,0,2.049,2.049" transform="translate(-1528.69 263.71)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-15">
        <path id="Контур_3598" data-name="Контур 3598" d="M1257.89-1019.646h120.351v-103.892H1257.89Z" transform="translate(-1257.89 1123.539)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-17">
        <path id="Контур_3601" data-name="Контур 3601" d="M1270.8-1013.69H1386.52v-100.09H1270.8Z" transform="translate(-1270.8 1113.78)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-19">
        <path id="Контур_3604" data-name="Контур 3604" d="M1381.227-1033.092H1355.87v-31.664l4.3-3.876h21.057v35.54" transform="translate(-1355.87 1068.632)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-21">
        <path id="Контур_3607" data-name="Контур 3607" d="M1355.87-1064.756h4.161l.138-3.876-4.3,3.876" transform="translate(-1355.87 1068.632)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-23">
        <path id="Контур_3610" data-name="Контур 3610" d="M1404.46-1050.869h8.12v-.387h-8.12Z" transform="translate(-1404.46 1051.256)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-25">
        <path id="Контур_3613" data-name="Контур 3613" d="M1393.69-1044.329h11.94v-.386h-11.94Z" transform="translate(-1393.69 1044.716)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-27">
        <path id="Контур_3616" data-name="Контур 3616" d="M1372.79-1002.22h19.828v-.5H1372.79Z" transform="translate(-1372.79 1002.717)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-29">
        <path id="Контур_3619" data-name="Контур 3619" d="M1372.79-993.709h19.828v-.5H1372.79Z" transform="translate(-1372.79 994.206)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-31">
        <path id="Контур_3622" data-name="Контур 3622" d="M1372.79-985.2h19.828v-.5H1372.79Z" transform="translate(-1372.79 985.694)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-33">
        <path id="Контур_3625" data-name="Контур 3625" d="M1372.79-976.686h19.828v-.5H1372.79Z" transform="translate(-1372.79 977.183)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-35">
        <path id="Контур_3628" data-name="Контур 3628" d="M1372.79-885.6h19.828v-.5H1372.79Z" transform="translate(-1372.79 886.099)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-37">
        <path id="Контур_3631" data-name="Контур 3631" d="M1546.3-785.413H1520.94v-31.664l4.3-3.876H1546.3v35.54" transform="translate(-1520.94 820.953)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-39">
        <path id="Контур_3634" data-name="Контур 3634" d="M1520.94-817.077h4.163l.138-3.876-4.3,3.876" transform="translate(-1520.94 820.953)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-41">
        <path id="Контур_3637" data-name="Контур 3637" d="M1569.54-803.19h8.118v-.387h-8.118Z" transform="translate(-1569.54 803.577)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-43">
        <path id="Контур_3640" data-name="Контур 3640" d="M1558.76-796.651H1570.7v-.387H1558.76Z" transform="translate(-1558.76 797.038)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-45">
        <path id="Контур_3643" data-name="Контур 3643" d="M1537.87-754.541H1557.7v-.5H1537.87Z" transform="translate(-1537.87 755.038)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-47">
        <path id="Контур_3646" data-name="Контур 3646" d="M1537.87-746.03H1557.7v-.5H1537.87Z" transform="translate(-1537.87 746.527)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-49">
        <path id="Контур_3649" data-name="Контур 3649" d="M1537.87-737.518H1557.7v-.5H1537.87Z" transform="translate(-1537.87 738.015)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-51">
        <path id="Контур_3652" data-name="Контур 3652" d="M1537.87-729.006H1557.7v-.5H1537.87Z" transform="translate(-1537.87 729.503)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-53">
        <path id="Контур_3655" data-name="Контур 3655" d="M1537.87-702.813H1557.7v-.5H1537.87Z" transform="translate(-1537.87 703.31)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-55">
        <path id="Контур_3658" data-name="Контур 3658" d="M1537.87-694.3H1557.7v-.5H1537.87Z" transform="translate(-1537.87 694.8)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-57">
        <path id="Контур_3661" data-name="Контур 3661" d="M1537.87-684.433H1557.7v-.5H1537.87Z" transform="translate(-1537.87 684.93)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-59">
        <path id="Контур_3664" data-name="Контур 3664" d="M1665.64-1056.288h14v-12.344h-14Z" transform="translate(-1665.64 1068.632)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-61">
        <path id="Контур_3667" data-name="Контур 3667" d="M1701.66-1063.19a.612.612,0,0,1,.612-.613.613.613,0,0,1,.614.613.613.613,0,0,1-.614.613.612.612,0,0,1-.612-.613" transform="translate(-1701.66 1063.803)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-63">
        <path id="Контур_3670" data-name="Контур 3670" d="M1663.31-976.192l13.968-.975.86,12.314-13.968.975-.86-12.314" transform="translate(-1663.31 977.167)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-65">
        <path id="Контур_3673" data-name="Контур 3673" d="M1700.113-964.648a.613.613,0,0,1,.569-.654.611.611,0,0,1,.653.569.612.612,0,0,1-.568.654.612.612,0,0,1-.654-.569" transform="translate(-1700.112 965.304)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-67">
        <path id="Контур_3676" data-name="Контур 3676" d="M1400.98-719.792l13.97-.975.859,12.314-13.968.976-.86-12.315" transform="translate(-1400.98 720.767)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-69">
        <path id="Контур_3679" data-name="Контур 3679" d="M1437.783-708.252a.614.614,0,0,1,.569-.654.616.616,0,0,1,.655.569.614.614,0,0,1-.569.654.612.612,0,0,1-.655-.569" transform="translate(-1437.782 708.908)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-71">
        <path id="Контур_3682" data-name="Контур 3682" d="M1301.71-670.676l14-.144.126,12.343-14,.144-.128-12.343" transform="translate(-1301.71 670.82)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-73">
        <path id="Контур_3685" data-name="Контур 3685" d="M1337.85-660.651a.615.615,0,0,1,.608-.619.613.613,0,0,1,.619.607.612.612,0,0,1-.606.619.614.614,0,0,1-.621-.607" transform="translate(-1337.85 661.27)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-75">
        <path id="Контур_3688" data-name="Контур 3688" d="M1773.8-935.129l13.783,2.463-2.171,12.152-13.783-2.463,2.171-12.152" transform="translate(-1771.63 935.129)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-77">
        <path id="Контур_3691" data-name="Контур 3691" d="M1818.072-921.47a.533.533,0,0,1,.619-.431.534.534,0,0,1,.431.619.534.534,0,0,1-.619.431.534.534,0,0,1-.431-.618" transform="translate(-1818.063 921.909)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-79">
        <path id="Контур_3694" data-name="Контур 3694" d="M1540.6-977.041l13.92-1.506,1.328,12.272-13.92,1.507-1.328-12.273" transform="translate(-1540.6 978.548)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-81">
        <path id="Контур_3697" data-name="Контур 3697" d="M1577.855-967.342a.532.532,0,0,1,.472-.587.533.533,0,0,1,.587.473.532.532,0,0,1-.472.587.532.532,0,0,1-.587-.473" transform="translate(-1577.852 967.933)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-83">
        <path id="Контур_3700" data-name="Контур 3700" d="M1336.97-811.113l13.693-2.92,2.575,12.073-13.695,2.92-2.574-12.073" transform="translate(-1336.97 814.033)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-85">
        <path id="Контур_3703" data-name="Контур 3703" d="M1374.764-798.631a.532.532,0,0,1-.156-.737.533.533,0,0,1,.738-.157.534.534,0,0,1,.156.737.534.534,0,0,1-.738.157" transform="translate(-1374.521 799.612)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-87">
        <path id="Контур_3706" data-name="Контур 3706" d="M1559.346-1056.288H1546.02v-8.954l3.689-3.39h9.636v12.344" transform="translate(-1546.02 1068.632)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-89">
        <path id="Контур_3709" data-name="Контур 3709" d="M1545.62-1065.242h3.64l.121-3.39-3.76,3.39" transform="translate(-1545.62 1068.632)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-91">
        <path id="Контур_3712" data-name="Контур 3712" d="M1583.86-1064.226a.438.438,0,0,1,.438-.438.438.438,0,0,1,.438.438.437.437,0,0,1-.438.438.438.438,0,0,1-.438-.438" transform="translate(-1583.86 1064.664)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-93">
        <path id="Контур_3715" data-name="Контур 3715" d="M1778.877-643.035H1765.55v-8.955l3.689-3.39h9.638v12.345" transform="translate(-1765.55 655.38)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-95">
        <path id="Контур_3718" data-name="Контур 3718" d="M1765.15-651.99h3.64l.121-3.39-3.76,3.39" transform="translate(-1765.15 655.38)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-97">
        <path id="Контур_3721" data-name="Контур 3721" d="M1803.39-650.972a.439.439,0,0,1,.438-.438.438.438,0,0,1,.438.438.438.438,0,0,1-.438.438.439.439,0,0,1-.438-.438" transform="translate(-1803.39 651.41)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-99">
        <path id="Контур_3724" data-name="Контур 3724" d="M1813.156-743.221H1799.83v-8.954l3.689-3.39h9.636v12.344" transform="translate(-1799.83 755.565)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-101">
        <path id="Контур_3727" data-name="Контур 3727" d="M1799.42-752.175h3.641l.121-3.39-3.762,3.39" transform="translate(-1799.42 755.565)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-103">
        <path id="Контур_3730" data-name="Контур 3730" d="M1837.67-751.159a.439.439,0,0,1,.438-.438.439.439,0,0,1,.438.438.439.439,0,0,1-.438.438.439.439,0,0,1-.438-.438" transform="translate(-1837.67 751.597)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-105">
        <path id="Контур_3733" data-name="Контур 3733" d="M1714-743.221H1700.67v-8.954l3.689-3.39H1714v12.344" transform="translate(-1700.67 755.565)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-107">
        <path id="Контур_3736" data-name="Контур 3736" d="M1700.27-752.175h3.64l.121-3.39-3.76,3.39" transform="translate(-1700.27 755.565)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-109">
        <path id="Контур_3739" data-name="Контур 3739" d="M1738.51-751.159a.439.439,0,0,1,.438-.438.439.439,0,0,1,.44.438.439.439,0,0,1-.44.438.439.439,0,0,1-.438-.438" transform="translate(-1738.51 751.597)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-111">
        <path id="Контур_3742" data-name="Контур 3742" d="M1834.358-1073.71H1821.03v-8.954l3.689-3.39h9.638v12.344" transform="translate(-1821.03 1086.054)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-113">
        <path id="Контур_3745" data-name="Контур 3745" d="M1820.63-1082.664h3.64l.121-3.39-3.76,3.39" transform="translate(-1820.63 1086.054)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-115">
        <path id="Контур_3748" data-name="Контур 3748" d="M1858.87-1081.648a.439.439,0,0,1,.438-.438.438.438,0,0,1,.438.438.437.437,0,0,1-.438.438.438.438,0,0,1-.438-.438" transform="translate(-1858.87 1082.086)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-117">
        <path id="Контур_3751" data-name="Контур 3751" d="M1025.766-1270.81h-25.35a2.122,2.122,0,0,1-2.116-2.116v-24.467a2.122,2.122,0,0,1,2.116-2.116h25.35a2.122,2.122,0,0,1,2.116,2.116v24.467a2.122,2.122,0,0,1-2.116,2.116" transform="translate(-998.3 1299.509)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-119">
        <path id="Контур_3754" data-name="Контур 3754" d="M1030.615-1266.287h-23.154a1.938,1.938,0,0,1-1.932-1.933v-22.347a1.938,1.938,0,0,1,1.932-1.933h23.154a1.939,1.939,0,0,1,1.933,1.933v22.347a1.939,1.939,0,0,1-1.933,1.933" transform="translate(-1005.53 1292.5)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-121">
        <path id="Контур_3757" data-name="Контур 3757" d="M1078.24-1280.574a.612.612,0,0,1,.612-.613.613.613,0,0,1,.614.613.613.613,0,0,1-.614.613.612.612,0,0,1-.612-.613" transform="translate(-1078.24 1281.187)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-123">
        <path id="Контур_3760" data-name="Контур 3760" d="M1105.755-1274.312a.613.613,0,0,1,.834-.238.612.612,0,0,1,.238.833.613.613,0,0,1-.834.238.615.615,0,0,1-.238-.833" transform="translate(-1105.678 1274.627)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-125">
        <path id="Контур_3763" data-name="Контур 3763" d="M1128.7-1254.329a.612.612,0,0,1,.846.183.613.613,0,0,1-.183.847.612.612,0,0,1-.846-.183.611.611,0,0,1,.183-.847" transform="translate(-1128.414 1254.427)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-127">
        <path id="Контур_3766" data-name="Контур 3766" d="M1138.984-1224.507a.613.613,0,0,1,.612.613.613.613,0,0,1-.612.613.613.613,0,0,1-.614-.613.613.613,0,0,1,.614-.613" transform="translate(-1138.37 1224.507)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-129">
        <path id="Контур_3769" data-name="Контур 3769" d="M1130.934-1191.257a.616.616,0,0,1,0,.866.613.613,0,0,1-.867,0,.612.612,0,0,1,0-.866.614.614,0,0,1,.867,0" transform="translate(-1129.887 1191.437)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-131">
        <path id="Контур_3772" data-name="Контур 3772" d="M1109.243-1169.506a.612.612,0,0,1,0,.866.611.611,0,0,1-.866,0,.613.613,0,0,1,0-.866.612.612,0,0,1,.866,0" transform="translate(-1108.198 1169.686)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-133">
        <path id="Контур_3775" data-name="Контур 3775" d="M1078.24-1160.3a.612.612,0,0,1,.612-.613.613.613,0,0,1,.614.613.613.613,0,0,1-.614.613.612.612,0,0,1-.612-.613" transform="translate(-1078.24 1160.917)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-135">
        <path id="Контур_3778" data-name="Контур 3778" d="M1051.831-1274.312a.613.613,0,0,0-.834-.238.612.612,0,0,0-.238.833.613.613,0,0,0,.834.238.614.614,0,0,0,.238-.833" transform="translate(-1050.682 1274.627)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-137">
        <path id="Контур_3781" data-name="Контур 3781" d="M1028.9-1254.329a.613.613,0,0,0-.848.183.614.614,0,0,0,.183.847.614.614,0,0,0,.848-.183.614.614,0,0,0-.183-.847" transform="translate(-1027.951 1254.427)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-139">
        <path id="Контур_3784" data-name="Контур 3784" d="M1018.6-1224.507a.613.613,0,0,0-.614.613.613.613,0,0,0,.614.613.613.613,0,0,0,.612-.613.613.613,0,0,0-.612-.613" transform="translate(-1017.99 1224.507)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-141">
        <path id="Контур_3787" data-name="Контур 3787" d="M1026.657-1191.257a.613.613,0,0,0,0,.866.611.611,0,0,0,.866,0,.612.612,0,0,0,0-.866.612.612,0,0,0-.866,0" transform="translate(-1026.478 1191.437)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-143">
        <path id="Контур_3790" data-name="Контур 3790" d="M1048.347-1169.506a.612.612,0,0,0,0,.866.613.613,0,0,0,.867,0,.612.612,0,0,0,0-.866.614.614,0,0,0-.867,0" transform="translate(-1048.167 1169.686)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-145">
        <path id="Контур_3793" data-name="Контур 3793" d="M1080.76-1223.894a.613.613,0,0,1,.612-.613.613.613,0,0,1,.614.613.613.613,0,0,1-.614.613.613.613,0,0,1-.612-.613" transform="translate(-1080.76 1224.507)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-147">
        <path id="Контур_3796" data-name="Контур 3796" d="M1083.74-1256.557h.168v-7.655h-.168Z" transform="translate(-1083.74 1264.212)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-149">
        <path id="Контур_3799" data-name="Контур 3799" d="M1083.21-1236.607l-.31-.395,4.06-3.186.309.395-4.058,3.186" transform="translate(-1082.9 1240.188)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-151">
        <path id="Контур_3802" data-name="Контур 3802" d="M1959.45-67.921c-.121,1.646-2.444,1.664-5.08,1.359s-1.935.36-5.626.412-4.961-1.169-5.14-1.7c-.16-.468,1.618-.862,3.519-1.325.239-.057.481-.117.72-.177,2.144-.546,3.563-2.15,5.4-3.551,1.738-1.328,3.98-6.132,3.98-6.132l5.041-.229s-2.123,4.307-2.921,6.164a6.1,6.1,0,0,0-.066,3.462,6.459,6.459,0,0,1,.176,1.713" transform="translate(-1943.594 79.26)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-153">
        <path id="Контур_3805" data-name="Контур 3805" d="M1954.37-21.918c-2.636-.307-1.935.36-5.626.411s-4.961-1.169-5.14-1.7c-.16-.468,1.618-.862,3.519-1.325l.023.035a10.347,10.347,0,0,0,5.415.97c2.478-.248,5.005-1.282,6.713-1.469a6.459,6.459,0,0,1,.176,1.713c-.121,1.646-2.444,1.664-5.08,1.359" transform="translate(-1943.594 24.99)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-155">
        <path id="Контур_3808" data-name="Контур 3808" d="M2155.76-81.631c-.119,1.648-2.442,1.667-5.078,1.364s-1.935.364-5.625.419-4.964-1.164-5.144-1.69c-.16-.468,1.618-.864,3.519-1.328l.718-.179c2.144-.548,3.562-2.153,5.4-3.556,1.736-1.33,0-9.2,0-9.2l5.546-.539s-.266,6.625-.263,9.637a16.993,16.993,0,0,0,.754,3.361,6.528,6.528,0,0,1,.176,1.712" transform="translate(-2139.904 96.34)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-157">
        <path id="Контур_3811" data-name="Контур 3811" d="M2150.682-19.984c-2.636-.3-1.935.364-5.625.419s-4.964-1.164-5.144-1.69c-.16-.468,1.618-.864,3.519-1.328l.021.034a10.345,10.345,0,0,0,5.417.965c2.478-.25,5-1.288,6.713-1.474a6.528,6.528,0,0,1,.176,1.712c-.119,1.648-2.442,1.667-5.078,1.364" transform="translate(-2139.904 23.06)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-159">
        <path id="Контур_3814" data-name="Контур 3814" d="M2136.581-830c-.954-3.926.429-5.755,1.678-6.728a5.96,5.96,0,0,1,1.958-2.879,7.073,7.073,0,0,1,3.828-.732,1.787,1.787,0,0,0-.807-.383,5.942,5.942,0,0,1,2.118.552c9.111-3.511,10.543,9.905,10.351,13.023s-.165,6.788,4.576,9.242c9.046,4.686-3.006,22.345-3.006,22.345.23-2.519-11.391-15.811-17.455-19.793-1.476-2.173-2-6.011-2.04-6.314a16.556,16.556,0,0,0,1.241,5.712c-2.763-2.543-3.173-8.651-2.442-14.046" transform="translate(-2136.262 840.755)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-161">
        <path id="Контур_3817" data-name="Контур 3817" d="M2158.349-738.077c-.031.014,2.928,1.548,5.152.94,2.093-.569,3.461-3.283,3.432-3.29-.887-.176-1.142-1.393-1.123-6.627l-.4.079-6.737,1.334s.153,2.12.158,4.117c0,1.677-.1,3.268-.482,3.447" transform="translate(-2158.349 747.054)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-163">
        <path id="Контур_3820" data-name="Контур 3820" d="M2160.18-745.275s.152,2.12.158,4.117c3.769-.106,5.762-3.545,6.578-5.451l-6.736,1.334" transform="translate(-2160.18 746.609)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-165">
        <path id="Контур_3823" data-name="Контур 3823" d="M2005.273-411.246a1.654,1.654,0,0,0,1.5,2.153l2.632.122,2.785.085a2.232,2.232,0,0,0,1.8-.823,78.254,78.254,0,0,0,13.216-25.02c.117-.371.24-.745.371-1.125,2.74-7.992,8.423-18.024,11.417-27.1.477-1.447.9-2.808,1.288-4.1q.186-.617.356-1.217a96.206,96.206,0,0,0,3.436-18.079l-21.623.172a476.222,476.222,0,0,1-17.177,74.932" transform="translate(-2005.194 486.35)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-167">
        <path id="Контур_3826" data-name="Контур 3826" d="M2105.017-685.389c.055,10.289-1.509,14.7-1.509,14.7a29.052,29.052,0,0,0,20.261,1.242c.129-.041,9.11-2.091,9.11-2.091-.183-.415-1.822-7.35-2-7.732-2.323-4.989-4.086-7.176-4.361-8.711a32.61,32.61,0,0,1-.268-8.62,69.124,69.124,0,0,1,2.668-13.537,70.585,70.585,0,0,1-8.925-3.051,6.976,6.976,0,0,1-4.816,1.786c-2.193-.05-2.9-.988-2.79-2.011-2.334,1.3-4.2,2.528-4.2,2.528-1.985,3.448-6.011,6.8-6.3,12.086-.073,1.3,3.13,13.12,3.132,13.407" transform="translate(-2101.883 713.41)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-169">
        <path id="Контур_3829" data-name="Контур 3829" d="M2209.324-631.891c.946,1.337,2.757,9.468,7.265,10.491-2.324-5.8-4.087-8.336-4.362-10.119a43.928,43.928,0,0,1-.268-10.013c-1.509-2.645-2.636-4.459-2.636-4.459-.119-.427-2.4-7.476-3.129-1.176s2.073,13.778,3.129,15.275" transform="translate(-2206.076 649.772)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-171">
        <path id="Контур_3832" data-name="Контур 3832" d="M2139.3-798.229s8.92.147,8.925-5.409,1.162-9.279-4.533-9.608-6.8,1.79-7.256,3.644.544,11.191,2.865,11.373" transform="translate(-2136.33 813.28)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-173">
        <path id="Контур_3835" data-name="Контур 3835" d="M2154.94-819.093s2.442,6.518,6.19,7.482,5.374-.04,5.374-.04a10.618,10.618,0,0,1-3.473-6.27s-6.249-4.274-8.092-1.171" transform="translate(-2154.94 820.222)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-175">
        <path id="Контур_3838" data-name="Контур 3838" d="M2137.406-815.8a7.882,7.882,0,0,0-2.269,2.87c-.444,1.415-.644,4.327-1.493,4.228,0,0-1.839-4.43.229-6.937,2.192-2.658,3.533-.162,3.533-.162" transform="translate(-2132.891 816.864)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-177">
        <path id="Контур_3841" data-name="Контур 3841" d="M2054.412-515.16l-4.213,12.843,2.4.963,6.965-11.346-5.156-2.46" transform="translate(-2050.2 515.16)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-179">
        <path id="Контур_3844" data-name="Контур 3844" d="M2080.455-703.43s-4.968,1.972-6.4,5.044-3.615,23.688-4.482,25.805-1.518,3.792-1.518,3.792a10.351,10.351,0,0,0,5.023,2.265c2.519.156,7.382-36.907,7.382-36.907" transform="translate(-2068.05 703.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-181">
        <path id="Контур_3847" data-name="Контур 3847" d="M2098.447-713.41s-5.16,12.749-5.312,15.328-.606,42.191-.606,42.191.3,1.822-3.187,1.062-4.4-1.516-4.4-1.516,3.338-24.435,2.428-32.934,6.981-22.361,6.981-22.361l4.1-1.77" transform="translate(-2084.94 713.41)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-183">
        <path id="Контур_3850" data-name="Контур 3850" d="M2028.92-336.324l3.024.092a1.744,1.744,0,0,0,1.4-.633,78.025,78.025,0,0,0,13.377-25.217c.117-.371.24-.745.371-1.125,2.74-7.992,8.423-18.024,11.417-27.1.477-1.447.9-2.808,1.288-4.1q.186-.617.356-1.217l-3.184-2.416s-9.56,15.479-12.519,30.048c-2.57,12.654-12.867,27.881-15.532,31.668" transform="translate(-2028.92 398.04)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-185">
        <path id="Контур_3853" data-name="Контур 3853" d="M2175.345-385.687c-.051,6.7.266,21.641,1.082,28.943a1.318,1.318,0,0,0,1.313,1.172H2180c.951,0,1.662-.007,2.134-.014a.866.866,0,0,0,.837-.7c2.244-11.417,3.849-20.9,5.025-29.1.984-6.859,1.857-12.74,2.529-17.8.678-5.094,1.147-9.36,1.316-12.966.027-.566.046-1.119.057-1.655s.014-1.062.009-1.571l-18.682-1.82c.236,12.412,2.155,31.4,2.125,35.513" transform="translate(-2173.22 421.2)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-187">
        <path id="Контур_3856" data-name="Контур 3856" d="M2211.42-332.168c.853,0,1.515-.005,1.983-.012a1.053,1.053,0,0,0,1.017-.85c2.229-11.352,3.826-20.789,5-28.95.984-6.859,1.857-12.74,2.529-17.8.678-5.094,1.148-9.36,1.316-12.966l-6.855,4.033s4.375,1.543,2.707,13.23-5.369,41.664-7.692,43.319" transform="translate(-2211.42 392.75)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-189">
        <path id="Контур_3859" data-name="Контур 3859" d="M2176.828-655.447s1.316,4.753,5.869,4.147,11.027-3.338,11.027-3.338-1.946-6.149-4.365-14.225c-2.928-9.778-6.554-22.384-8.263-30.336a48.906,48.906,0,0,1-.927-5.422l-3.778-7.329s-5.631,12.641-5.937,15.828,6.375,40.674,6.375,40.674" transform="translate(-2170.443 711.95)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-191">
        <path id="Контур_3862" data-name="Контур 3862" d="M2150.457-696.6l-4.984-15.353a76.949,76.949,0,0,1,9.458,3.468s1.974,2.547,3.2,8.422c0,0,1.293,14.108-1.591,19.268,0,0-9.106,1.822-23.675-3.187v-3.338s15.935.834,19.2,1.213l-1.6-10.491" transform="translate(-2132.86 711.95)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-193">
        <path id="Контур_3865" data-name="Контур 3865" d="M2207.562-630.3c.2,5.16,1.417,15.176,12.244,20.538.028.014.057.03.085.043-2.928-9.778-6.554-22.384-8.264-30.336-1.989,2.971-4.175,6.914-4.065,9.755" transform="translate(-2207.558 640.06)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-195">
        <path id="Контур_3868" data-name="Контур 3868" d="M2201.49-711.95s9.241.653,11.467,5.61,2.934,21.449.1,26.306c0,0-3.9,1.11-10.8-.179l-.256-.048s-2.2-5.388-.227-6.451c0,0,5.818.3,5.209-1.619s-5.5-23.619-5.5-23.619" transform="translate(-2200.955 711.95)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-197">
        <path id="Контур_3871" data-name="Контур 3871" d="M2073.33-583.332l.271.874,15.165-4.716a.585.585,0,0,0,.1-.537.43.43,0,0,0-.374-.339l-15.165,4.718" transform="translate(-2073.33 588.05)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-199">
        <path id="Контур_3874" data-name="Контур 3874" d="M2067.15-560.623l1.1-.827.271.874-1.368-.048" transform="translate(-2067.15 561.45)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-201">
        <path id="Контур_3877" data-name="Контур 3877" d="M2032.257-444.73s-3.847,2.127-4.354,3.746-.158,5.011.452,4.961,1.522-3.645,2.029-3.9,1.215-1.011,1.215-.454-.507,1.568-.254,1.72,1.062-1.417,1.619-1.72.791-2.808,1.332-3.48-2.04-.874-2.04-.874" transform="translate(-2027.66 444.73)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-203">
        <path id="Контур_3880" data-name="Контур 3880" d="M2092.473-581.555s-3.441-2.12-4.885-1.793-5.121,2.99-3.352,3.567,3.2-.028,3.883.39,1.183,1.185,4.354,1.174,0-3.338,0-3.338" transform="translate(-2083.765 583.383)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-205">
        <path id="Контур_3883" data-name="Контур 3883" d="M571.837-860.818c-.577-1.112,12.582-5.118,12.791,5.86,0,0,24.65-.607,12.88-14.346-4.075-4.757-3.655-8.689-3.533-12.35s-2.538-14.3-11.662-13.231a6.794,6.794,0,0,0-5.371.575c-6.782,3.518-21.973,37.539-5.1,33.492" transform="translate(-564.156 895.186)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-207">
        <path id="Контур_3886" data-name="Контур 3886" d="M834.67-645.686s1.6-2.442,2.9-2.49,3.688-.718,3.5-.239-1.676.527-1.82.814,2.2.718,2.873.766,2.251,0,2.2.335-2.849,1.724-4.825,1.532a13.4,13.4,0,0,0-4.419.575l-.407-1.293" transform="translate(-834.67 648.587)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-209">
        <path id="Контур_3889" data-name="Контур 3889" d="M703.84-718.239a24.091,24.091,0,0,0,2.454,3.711c1.907,2.387,4.847,5.147,8.538,5.692a9.171,9.171,0,0,0,5.072-.823l.006,0c4.483-1.99,7.854-6.566,7.854-6.566a3.686,3.686,0,0,0-.5-3.617s-9.052,2.036-9.914,1.605-5.891-14.92-7.088-16.74-5.279-2.766-5.279-2.766l-1.139,19.506" transform="translate(-703.84 737.745)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-211">
        <path id="Контур_3892" data-name="Контур 3892" d="M716.355-629.1c1.907,2.387,4.847,5.147,8.538,5.692a9.172,9.172,0,0,0,5.072-.823c-.175.023-4.653.576-6.437-2.616-1.82-3.258-3.789-8.43-6.493-6.706-1.167.743-1.115,2.593-.68,4.454" transform="translate(-716.07 633.893)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-213">
        <path id="Контур_3895" data-name="Контур 3895" d="M502.8-82.406s-2.762,2.522-2.223,4.312,3.032,2.985,3.368,5.176,1.684,3.382,2.9,3.382h5.781s.893-1.541-1.263-2-2.969-3.037-2.834-5.426a42.06,42.06,0,0,0-.2-5.571L503.742-83.6l-.943,1.194" transform="translate(-500.506 83.6)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-215">
        <path id="Контур_3898" data-name="Контур 3898" d="M643.466-70.83s-.994,10.678.219,11.275l15.519-.032s0-1.791-2.493-2.189-6.872-1.857-7.613-5.108-5.633-3.946-5.633-3.946" transform="translate(-643.082 70.83)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-217">
        <path id="Контур_3901" data-name="Контур 3901" d="M582.565-742.39c3.194-7.162,5.148-9.218,4.775-11.44-.278-1.655.845-30.849.845-30.849.379-.117,1.138-.223,1.554-.315a3.9,3.9,0,0,0,2.845-2.475,19.861,19.861,0,0,0,.966-6.587l.409.081,6.879,1.362s-.157,2.165-.161,4.2c0,.017,0,.034,0,.051a3.957,3.957,0,0,0,2.855,3.825c.869.251,1.625.465,1.625.465a63.074,63.074,0,0,1,3.008,14.619c.482,7.409-4.593,10.626.038,25.429,4.728,15.121-27.4,5.585-25.638,1.628" transform="translate(-582.495 794.055)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-219">
        <path id="Контур_3904" data-name="Контур 3904" d="M654.01-792.239s-.157,2.165-.161,4.2c-3.849-.109-5.884-3.62-6.717-5.566l6.879,1.362" transform="translate(-647.131 793.601)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-221">
        <path id="Контур_3907" data-name="Контур 3907" d="M650.418-846.307s-9.107.15-9.113-5.523-1.186-9.474,4.628-9.81,6.947,1.828,7.409,3.721-.555,11.427-2.924,11.612" transform="translate(-641.202 861.674)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-223">
        <path id="Контур_3910" data-name="Контур 3910" d="M636.5-867.609s-2.493,6.655-6.32,7.639-5.487-.041-5.487-.041a10.837,10.837,0,0,0,3.545-6.4s6.381-4.364,8.262-1.2" transform="translate(-624.692 868.761)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-225">
        <path id="Контур_3913" data-name="Контур 3913" d="M687.774-864.248a5.65,5.65,0,0,1,2.61,2.04A9.169,9.169,0,0,1,691.615-857s1.879-4.524-.233-7.083c-2.239-2.714-3.609-.165-3.609-.165" transform="translate(-687.774 865.334)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-227">
        <path id="Контур_3916" data-name="Контур 3916" d="M681.987-796.35a2.289,2.289,0,0,0,1.886.767,1.674,1.674,0,0,0,1.4-.926,6.118,6.118,0,0,0-3.289.159" transform="translate(-681.987 796.669)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-229">
        <path id="Контур_3919" data-name="Контур 3919" d="M577.459-728.281a61.756,61.756,0,0,0,0,7.173c.154,2.593,2.777,6.068,3.149,6.926.934,2.155,1.987,2.506,1.987,2.506l8.148,2,2.865.7,9.035-2.7s4.115-11.486,2.463-17.953a49.7,49.7,0,0,0-4.177-10.955s-7.373,7.218-16.972-.612l-6.5,12.915" transform="translate(-577.356 741.196)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-231">
        <path id="Контур_3922" data-name="Контур 3922" d="M591.382-634.145a25.357,25.357,0,0,0,.764,5.867c.934,2.155,1.987,2.506,1.987,2.506l8.148,2,3.539-1.041s-11.814-1.79-10.5-11.955l-3.934,2.625" transform="translate(-591.382 636.77)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-233">
        <path id="Контур_3925" data-name="Контур 3925" d="M647.162-564.93s4.474,13.927,4.762,18.668-8.553,67.36-8.909,69.545c-.335,2.059-2.893,3.372-2.893,3.372a3.871,3.871,0,0,1-2.855.22,7.061,7.061,0,0,1-3.065-1.446s-3.483-44.658-1.327-60.611a252.651,252.651,0,0,0,2.181-29.748h12.106" transform="translate(-632.171 564.93)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-235">
        <path id="Контур_3928" data-name="Контур 3928" d="M534.9-567.27s-6.825,9.158-7.461,15.348-1,27.934-2.354,33.97-6.336,17.648-6.336,36.471a3.755,3.755,0,0,0,2.116,1.653,16.867,16.867,0,0,0,1.861.514A2.924,2.924,0,0,0,526-480.866c3.367-6.779,14.093-28.645,14.643-33.184.389-3.212,3.776-24.472,11.163-36.946,1.773-2.994-1.089-14.9-1.089-14.9L534.9-567.27" transform="translate(-518.751 567.27)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-237">
        <path id="Контур_3931" data-name="Контур 3931" d="M683.128-796s.158.669,1.821.565c0,0,1.4-.086,1.354-.821a4.492,4.492,0,0,0-3.174.255" transform="translate(-683.128 796.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-239">
        <path id="Контур_3934" data-name="Контур 3934" d="M625-577.05a9.375,9.375,0,0,1-5.365,1.492c-4.262.087-14.824-1.086-14.824-1.086a8.727,8.727,0,0,0-.45,2.694s12.9,3.255,20.373.475A4.721,4.721,0,0,0,625-577.05" transform="translate(-604.357 577.05)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-241">
        <path id="Контур_3937" data-name="Контур 3937" d="M685.918-570.549a1.448,1.448,0,0,0,1.3,1.557,1.448,1.448,0,0,0,1.3-1.557,1.448,1.448,0,0,0-1.3-1.557A1.448,1.448,0,0,0,685.918-570.549Zm-.645,0a2.088,2.088,0,0,1,1.945-2.2,2.088,2.088,0,0,1,1.945,2.2,2.088,2.088,0,0,1-1.945,2.2,2.088,2.088,0,0,1-1.945-2.2Z" transform="translate(-685.273 572.75)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-243">
        <path id="Контур_3940" data-name="Контур 3940" d="M528.609-646.75l13.215,11.508,15.752-18.09L544.361-664.84l-15.752,18.09" transform="translate(-528.609 664.84)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-245">
        <path id="Контур_3943" data-name="Контур 3943" d="M619.444-562.61s4.842.566,4.827,1.715-1.206,3.51-1.8,3.617-3.941-3.194-3.941-3.194l.918-2.137" transform="translate(-618.526 562.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-247">
        <path id="Контур_3946" data-name="Контур 3946" d="M539.8-741.2s-6.465.453-8.943,6.477-7.18,16.139-7.18,17.84,2.626,6.948,16.731,9.963c0,0,1.459-1.534,1.371-2.747,0,0-8.8-7.563-8.781-8.448.045-1.671,1.443-3.879,9.174-11.611,2.1-2.1-2.372-11.473-2.372-11.473" transform="translate(-523.677 741.196)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-249">
        <path id="Контур_3949" data-name="Контур 3949" d="M1013.993-206.892h-.062a.642.642,0,0,1-.642-.658l.976-42.285,2.873-.335-2.451,42.624a.694.694,0,0,1-.694.654" transform="translate(-1013.289 250.17)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-251">
        <path id="Контур_3952" data-name="Контур 3952" d="M919.573-206.892h-.062a.642.642,0,0,1-.642-.658l.976-42.285,2.873-.335-2.451,42.624a.694.694,0,0,1-.693.654" transform="translate(-918.869 250.17)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-253">
        <path id="Контур_3955" data-name="Контур 3955" d="M1168.795-206.892h.062a.645.645,0,0,0,.644-.658l-.977-42.285-2.873-.335,2.451,42.624a.694.694,0,0,0,.693.654" transform="translate(-1165.65 250.17)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-255">
        <path id="Контур_3958" data-name="Контур 3958" d="M912.1-440.281v-28.977a9.234,9.234,0,0,1,9.232-9.232h20.876a9.232,9.232,0,0,1,9.232,9.232v30.084L912.1-440.281" transform="translate(-912.1 478.49)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-257">
        <path id="Контур_3961" data-name="Контур 3961" d="M955.181-258.5H914.872a2.773,2.773,0,0,1-2.772-2.772v-1.8h43.081v4.574" transform="translate(-912.1 263.07)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-259">
        <path id="Контур_3964" data-name="Контур 3964" d="M1031.286-258.5h-30.5a2.048,2.048,0,0,1-2.049-2.049v-.475a2.049,2.049,0,0,1,2.049-2.05h30.5a2.049,2.049,0,0,1,2.049,2.05v.475a2.048,2.048,0,0,1-2.049,2.049" transform="translate(-998.74 263.07)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-261">
        <path id="Контур_3967" data-name="Контур 3967" d="M1071.652-307.733a19.416,19.416,0,0,1-6.752,7.215H1049.25s24.683-11.185,22.4-7.215" transform="translate(-1049.25 308.585)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-263">
        <path id="Контур_3970" data-name="Контур 3970" d="M1106.058-136.918a1.445,1.445,0,0,0-.259,1.547.013.013,0,0,0,0,.007,9.627,9.627,0,0,0,3.04,3.335c.183.147.346.282.488.406.037.032.074.066.108.1s.057.053.085.076c1.335,1.231.832,1.513,3.232,4.012,2.737,2.847,4.62,2.9,5.156,2.639.454-.222-.466-1.731-1.488-3.429-.05-.082-.1-.165-.149-.247l-.406-.683c-1.2-2.042-.889-3-.6-6.039.231-2.471,3.844-13.8,3.844-13.8l-5.245-.9s-1.579,9.023-3.319,10.412c-.929.741-1.972.75-2.866,1.3-.062.039-.124.078-.185.119a6.85,6.85,0,0,0-1.435,1.153" transform="translate(-1105.684 149.9)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-265">
        <path id="Контур_3973" data-name="Контур 3973" d="M1106.058-82.608a1.445,1.445,0,0,0-.259,1.547.013.013,0,0,0,0,.007,11.173,11.173,0,0,0,3.044,3.324c.181.151.34.287.484.417.037.032.074.066.108.1s.057.053.085.076c1.335,1.231.832,1.513,3.232,4.012,2.737,2.847,4.62,2.9,5.156,2.639.454-.222-.466-1.731-1.488-3.429a11.674,11.674,0,0,1-4.878-3.594c-1.648-2.1-2.746-4.8-3.868-6.369-.062.039-.124.078-.185.119a6.849,6.849,0,0,0-1.435,1.153" transform="translate(-1105.684 83.88)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-267">
        <path id="Контур_3976" data-name="Контур 3976" d="M1326.713-147.59c.325,1.619,2.632,1.35,5.209.718s1.965.121,5.633-.286,4.78-1.775,4.892-2.32c.1-.484-1.714-.654-3.657-.878-.245-.028-.49-.059-.736-.089-2.194-.275-3.8-1.692-5.8-2.856-1.889-1.1-4.707-5.591-4.707-5.591l-5.03.4s2.639,4.01,3.661,5.754a6.079,6.079,0,0,1,.495,3.429,6.5,6.5,0,0,0,.037,1.72" transform="translate(-1322.52 158.89)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-269">
        <path id="Контур_3979" data-name="Контур 3979" d="M1351.018-107.286c2.577-.63,1.965.121,5.633-.286s4.78-1.775,4.892-2.32c.1-.484-1.713-.654-3.657-.878l-.018.037a10.34,10.34,0,0,1-5.254,1.632c-2.488.06-5.124-.653-6.843-.624a6.5,6.5,0,0,0,.037,1.72c.325,1.619,2.632,1.35,5.209.718" transform="translate(-1345.732 110.77)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-271">
        <path id="Контур_3982" data-name="Контур 3982" d="M1157.41-313.954l-10.843,29.181a9.987,9.987,0,0,1-3.262-.3,14.208,14.208,0,0,1-3.156-1.536s1.706-17.742,4.887-27.927a18.115,18.115,0,0,1,2.737-5.921c4.929-5.827,9.636,6.5,9.636,6.5" transform="translate(-1140.15 322.019)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-273">
        <path id="Контур_3985" data-name="Контур 3985" d="M999.518-401.582c-3.081-.993-11.868.282-20.7,2.01l-1.848-3.049-19.069-1.488s-6.291,13.954-2.847,22.843a3.227,3.227,0,0,0,3.228,2.173h.279s11.348-.007,15.032-.007c9.484,0,20.069-6.518,21.439-6.792,2.242-.449,3.138,4.035,5.38,8.741s21.07,20.847,21.07,20.847a16.563,16.563,0,0,0,4.088-.421,4.027,4.027,0,0,0,1.756-1.109s-20.862-41.506-27.812-43.748" transform="translate(-954.028 404.11)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-275">
        <path id="Контур_3988" data-name="Контур 3988" d="M929.267-716.164c-2.442-1.283-6.16-1.457-6.763-1.538-7.226-.971-11.575,9.528-11.831,12.71-.472,5.864-3.574,6.758-7.939,10.429-8.755,7.364-6.188,15.921-1.4,19.1,2.873,1.908,7.935,0,7.935,0,.071.05,13.846-7.7,20.635-11.439,10.179-1.447,5.587-25.994-.637-29.262" transform="translate(-896.946 717.765)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-277">
        <path id="Контур_3991" data-name="Контур 3991" d="M1007.589-611.222c.03.02-3.342,1.169-5.594.172-2.121-.938-3.727-4.425-3.7-4.427.965-.044,2.024-.889,2.84-6.423l.411.149,6.905,2.485s-.5,2.215-.825,4.324c-.271,1.772-.419,3.469-.041,3.719" transform="translate(-998.298 621.9)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-279">
        <path id="Контур_3994" data-name="Контур 3994" d="M1023.535-618.575s-.5,2.215-.825,4.324c-3.966-.713-5.523-4.665-6.08-6.809l6.905,2.485" transform="translate(-1016.63 621.06)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-281">
        <path id="Контур_3997" data-name="Контур 3997" d="M1021.35-671.737s-9.448-1.27-8.569-7.141.254-9.99,6.323-9.429,6.9,2.978,7.086,5.009-2.361,11.738-4.84,11.561" transform="translate(-1012.724 688.357)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-283">
        <path id="Контур_4000" data-name="Контур 4000" d="M1013.274-688.92s-6.048,5.211-10.161,5.631-5.672-.9-5.672-.9a16.07,16.07,0,0,0,3.884-5.263,2.891,2.891,0,0,1,2.386-1.7c2.937-.254,8.5-.369,9.564,2.237" transform="translate(-997.44 691.282)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-285">
        <path id="Контур_4003" data-name="Контур 4003" d="M1065.4-685.851a14.936,14.936,0,0,1,2.661,3.407,11.452,11.452,0,0,1,.309,6.08s3.154-6.13,1.368-9.108c-1.892-3.159-4.337-.38-4.337-.38" transform="translate(-1065.4 686.981)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-287">
        <path id="Контур_4006" data-name="Контур 4006" d="M977.94-568.549c-.286,19.126-.078,15.128-2.169,16.944-.505.436-1.923.7-3.787.819-5.866.39-16.174-.576-16.566-1.66-1.353-3.75-.4-4.915-1.525-10.656-.119-.61-.264-1.272-.435-1.992-.942-3.943-1.857-5.284,1.481-11.62,3.028-5.738,6.172-10.4,6.456-10.227,7.987,4.741,8.631.756,8.631.756s8.054,8.306,7.914,17.636" transform="translate(-952.586 586.945)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-289">
        <path id="Контур_4009" data-name="Контур 4009" d="M1152.708-266.585l-6.141,16.526a9.986,9.986,0,0,1-3.262-.3,14.209,14.209,0,0,1-3.156-1.536s1.706-17.742,4.887-27.927l7.671,13.235" transform="translate(-1140.15 279.82)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-291">
        <path id="Контур_4012" data-name="Контур 4012" d="M1050.91-582.67s8.219,1.394,9.184,4.933,6.644,24.34,6.644,24.34-4.177.752-6.109-1.178-6.715-17.909-6.715-17.909l-3-10.186" transform="translate(-1050.91 582.67)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-293">
        <path id="Контур_4015" data-name="Контур 4015" d="M978.042-477.148c-5.866.39-16.174-.576-16.566-1.66-1.353-3.75-.4-4.915-1.525-10.656l2.827-7.966s-.466,8.87,2.148,11.2,10.71,4.5,11.981,6.453a7.127,7.127,0,0,1,1.135,2.627" transform="translate(-959.95 497.43)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-295">
        <path id="Контур_4018" data-name="Контур 4018" d="M1024.07-408.264s5.786-1.288,6.719-.775a13.643,13.643,0,0,1,3.5,3.035c.286.651-2.079-.495-2.849-.7a6.848,6.848,0,0,0-2.457.537,8.162,8.162,0,0,1-4.4.443l-.511-2.54" transform="translate(-1024.07 409.16)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-297">
        <path id="Контур_4021" data-name="Контур 4021" d="M915.293-586.95s-7.769.479-10.458,4.23c-3.787,5.28-11.3,27.781-6.992,30.784,4.848,3.379,22.059.883,22.059.883s.573-2.677-.224-3.973-13.748-3.386-14.346-3.984,7.471-11.657,7.471-13.45,2.488-14.491,2.488-14.491" transform="translate(-896.551 586.95)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-299">
        <path id="Контур_4024" data-name="Контур 4024" d="M1063.53-399.4a11.165,11.165,0,0,0,1.609,1.751,1.193,1.193,0,0,0,.655.14l-.816-1.47-1.447-.42" transform="translate(-1063.53 399.4)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-301">
        <path id="Контур_4027" data-name="Контур 4027" d="M1061.95-408.48l3.524,1.712a.63.63,0,0,1-.584.225,8.364,8.364,0,0,1-1.529-.42l-1.412-1.517" transform="translate(-1061.95 408.48)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-303">
        <path id="Контур_4030" data-name="Контур 4030" d="M1072.6-472.753h27.881l8.388-20.857H1081.03l-8.43,20.857" transform="translate(-1072.6 493.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-305">
        <path id="Контур_4033" data-name="Контур 4033" d="M1060.885-380.61H1024.07v.814h36.489l.326-.814" transform="translate(-1024.07 380.61)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-307">
        <path id="Контур_4036" data-name="Контур 4036" d="M1177.547-440.973a.965.965,0,0,0-.947-1.327,1.9,1.9,0,0,0-1.708,1.327.968.968,0,0,0,.947,1.328,1.9,1.9,0,0,0,1.708-1.328" transform="translate(-1174.839 442.3)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-309">
        <path id="Контур_4039" data-name="Контур 4039" d="M1874.3-282.862h.082a.88.88,0,0,0,.851-.922l-1.291-59.206-3.8-.47,3.24,59.68a.944.944,0,0,0,.917.919" transform="translate(-1870.14 343.46)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-311">
        <path id="Контур_4042" data-name="Контур 4042" d="M1020.522-282.862h-.082a.88.88,0,0,1-.851-.922l1.291-59.206,3.8-.47-3.241,59.68a.944.944,0,0,1-.917.919" transform="translate(-1019.589 343.46)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-313">
        <path id="Контур_4045" data-name="Контур 4045" d="M1154.056-367.806h-162.6a2.412,2.412,0,0,1-2.412-2.412v-2.32a2.412,2.412,0,0,1,2.412-2.412h162.6a2.411,2.411,0,0,1,2.41,2.412v2.32a2.411,2.411,0,0,1-2.41,2.412" transform="translate(-989.04 374.95)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-315">
        <path id="Контур_4048" data-name="Контур 4048" d="M802.748-1048.876c-.614-15.2,12.127-21.956,25.157-21.956s27.207,3.376,28.434,23.59c.791,13-6.142,21.007-26.1,21.007-6.733,0-11.965-1.062-15.974-2.956l-9.978,8.867,4.9-12.256c-4.344-4.039-6.174-9.736-6.439-16.3" transform="translate(-802.727 1070.832)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-317">
        <path id="Контур_4051" data-name="Контур 4051" d="M2472.35-27.738a17.707,17.707,0,0,1,1.215-4.553c.85-1.7,6.252-.619,6.252-.619s1.08.928,1.313,5.25l-8.78-.078" transform="translate(-2472.35 33.248)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-319">
        <path id="Контур_4054" data-name="Контур 4054" d="M2288.832-44.281c1.268-.775.546-6.556.546-6.556s-5.915-4.732-6.729-1.109-5.619,5.25-8.356,5.694a2.936,2.936,0,0,0-2.654,1.971h17.192" transform="translate(-2271.64 53.312)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-321">
        <path id="Контур_4057" data-name="Контур 4057" d="M2344.18-444.073a27.24,27.24,0,0,0-.435,3.2c-.55,10.726-9.03,35.252-10.546,35.823-2.346.885-6.041-1-7.974-1.612a1.657,1.657,0,0,1-1.164-1.884c4.2-19.3,5.741-47.1,6.3-63.56a74.676,74.676,0,0,1,1.706-14.014s20.681-5.518,20.8,1.462c.108,6.176-6.85,30.4-8.694,40.589" transform="translate(-2324.026 488.194)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-323">
        <path id="Контур_4060" data-name="Контур 4060" d="M2373.575-444.073,2359.76-472.11a74.676,74.676,0,0,1,1.706-14.014s20.681-5.518,20.8,1.462c.108,6.176-6.85,30.4-8.695,40.589" transform="translate(-2359.76 488.194)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-325">
        <path id="Контур_4063" data-name="Контур 4063" d="M2411.71-479.832l10.931,79.236s4.8,1.158,8.535.232c0,0,3.2-42.143,2.936-52.331s2.3-27.262-.585-29.144-21.816,2.006-21.816,2.006" transform="translate(-2411.71 482.354)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-327">
        <path id="Контур_4066" data-name="Контур 4066" d="M2397.538-700.509a1.516,1.516,0,0,1-1.094,1.568c-1.525.442-3.283.75-6.675,1.453a64.827,64.827,0,0,1-12.735,1.281c-8.375.053-11.545-2.834-11.545-2.834-.362.007.189-1.745.686-10.078.168-2.824.33-6.4.447-10.924.106-4.069.177-8.9.184-14.638.009-6.9,10.688-14.322,10.885-14.322l0,0c0,.993,1.566,2.826,3.977,2.985,2.863.187,6.373-1.237,6.373-2.885a60.3,60.3,0,0,1,7.011,17.114c.929,4.447.553,4.328,2.483,31.279" transform="translate(-2365.373 749.004)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-329">
        <path id="Контур_4069" data-name="Контур 4069" d="M2351.415-749s-9.931-.084-12.32,3.267-7.628,21.382-4.9,27.381,7.851-2.481,10.69-6.156S2351.415-749,2351.415-749" transform="translate(-2333.429 749.003)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-331">
        <path id="Контур_4072" data-name="Контур 4072" d="M2434.12-785.009l.005,1.026s-.28,3.21,3.673,3.679,6.887-.6,6.779-3.077a35.727,35.727,0,0,1-.713-5.474,11.282,11.282,0,0,1,.142-1.922l-7.661-.153-2.256.369.03,5.553" transform="translate(-2434.09 790.93)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-333">
        <path id="Контур_4075" data-name="Контур 4075" d="M2434.09-790.561l.03,5.553a6.473,6.473,0,0,0,2.76.609c3.521,0,5.8-2.582,6.985-4.455a11.282,11.282,0,0,1,.142-1.922l-7.661-.153-2.256.369" transform="translate(-2434.09 790.93)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-335">
        <path id="Контур_4078" data-name="Контур 4078" d="M2434.659-852.886c-.889,4.783-7.061,4.626-9.729,4.325-.75-.083-1.222-.179-1.222-.179-.846-.226-1.32-1.559-1.538-3.305-.436-3.456.114-8.528.7-9.826.88-1.952,2.535-4.064,8.75-2.54s4.183,5.4,3.042,11.525" transform="translate(-2422.013 864.933)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-337">
        <path id="Контур_4081" data-name="Контур 4081" d="M2360.277-673.25s-3.84,8.482-3.728,11.025a13.24,13.24,0,0,1,3.728.479c3,.894,0-11.5,0-11.5" transform="translate(-2356.546 673.25)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-339">
        <path id="Контур_4084" data-name="Контур 4084" d="M2415.793-882.967a20.578,20.578,0,0,0,5.963-1.614c2.618-1.139,5.922.115,7,.68a1.575,1.575,0,0,0-.263-.645,1.441,1.441,0,0,1,.554.819c.049.036.071.062.058.07a1.847,1.847,0,0,1,.9.058s-.246-.035-.333.123a.141.141,0,0,1-.039.043c4.085,1.974,4.462,8.43,3.146,9.924a52.04,52.04,0,0,0-3.97,5.864,7.362,7.362,0,0,1,.089-2.557c.287-1.582-1.079-1.582-2.8-2.3s-.288-2.732-1.8-3.954-7.045-.5-6.972-1.438c-2.056-.748-1.524-4.863-1.524-5.073" transform="translate(-2415.731 885.042)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-341">
        <path id="Контур_4087" data-name="Контур 4087" d="M2393.04-585.365c-1.621,6.509-19.154,9.874-23.14,10.559.168-2.824.33-6.4.447-10.924l22.693.365" transform="translate(-2369.9 585.73)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-343">
        <path id="Контур_4090" data-name="Контур 4090" d="M2473.634-603.558a30.964,30.964,0,0,0,4.567-.89c.667-.335,3.723-4.677,1.722-5.791s-7.373,3.34-7.373,3.34l1.084,3.342" transform="translate(-2472.55 610.418)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-345">
        <path id="Контур_4093" data-name="Контур 4093" d="M2350.112-600.619s14.427.784,24.11-1.289a4.163,4.163,0,0,0-2.8-4.041s-15.092-3.2-18.718-2.308-2.595,7.638-2.595,7.638" transform="translate(-2349.977 608.413)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-347">
        <path id="Контур_4096" data-name="Контур 4096" d="M2480.4-727.122c-2.994,3.611-6.909,6.559-11.224,7.249h0a8.058,8.058,0,0,1-1.068-4.819,9.655,9.655,0,0,1,1.572-4.424,9.8,9.8,0,0,0,.848-1.71c2.357-6.013,2.006-17.6,2.006-17.6s10.007,1.048,13.125,5.5c1.91,2.729-.525,10.095-5.257,15.8" transform="translate(-2468.073 748.427)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-349">
        <path id="Контур_4099" data-name="Контур 4099" d="M2425.66-788.792c-.75-.084-1.222-.179-1.222-.179-.846-.226-1.319-1.559-1.538-3.305a4.183,4.183,0,0,1,2.616,2.139,3.073,3.073,0,0,1,.144,1.345" transform="translate(-2422.9 792.276)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-351">
        <path id="Контур_4102" data-name="Контур 4102" d="M2407.422-785.086s-3.455-5.7-.768-5.951a4.066,4.066,0,0,1,4.156,2.239c.388.668-.1,3.682-.1,3.682l-3.287.03" transform="translate(-2405.631 791.06)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-353">
        <path id="Контур_4105" data-name="Контур 4105" d="M2425.335-736.956a7.512,7.512,0,0,1-.589,1.979,9.474,9.474,0,0,1-2.2,3.031h0c-8.975-4.053-10.792-17.964-11.1-25.609-.123-3.073,0-5.133,0-5.133,4.049-1.341,5.188.989,5.188.989s4.78,11.37,7.26,18.8c.949,2.843,1.561,5.11,1.44,5.942" transform="translate(-2411.393 763.103)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-355">
        <path id="Контур_4108" data-name="Контур 4108" d="M2434.022-713.46c-2.994,3.611-6.909,6.559-11.224,7.249h0c-8.975-4.053-10.791-17.964-11.1-25.609,0,0,4.248,15.4,10.029,20.79a6.312,6.312,0,0,0,3.271,1.788s7.29-2.059,8.819-4a.815.815,0,0,1,.2-.218" transform="translate(-2411.7 731.82)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-357">
        <path id="Контур_4111" data-name="Контур 4111" d="M1447.629-471.683h-27.881l-8.388-20.857H1439.2l8.43,20.857" transform="translate(-1411.36 492.54)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-359">
        <path id="Контур_4114" data-name="Контур 4114" d="M1456.81-379.53h36.815v.812h-36.489l-.326-.812" transform="translate(-1456.81 379.53)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-361">
        <path id="Контур_4117" data-name="Контур 4117" d="M1498.078-439.9a.966.966,0,0,1,.947-1.328,1.9,1.9,0,0,1,1.708,1.328.966.966,0,0,1-.947,1.327,1.9,1.9,0,0,1-1.708-1.327" transform="translate(-1498.024 441.23)" fill="none" clip-rule="evenodd"/>
      </clipPath>
      <clipPath id="clip-path-363">
        <path id="Контур_4120" data-name="Контур 4120" d="M513.564-7.192v.177a.554.554,0,0,1-.58.516H1.541A.554.554,0,0,1,.96-7.015v-.177a.555.555,0,0,1,.581-.518H512.984a.555.555,0,0,1,.58.518" transform="translate(-0.96 7.71)" fill="none" clip-rule="evenodd"/>
      </clipPath>
    </defs>
    <g id="Сгруппировать_4450" data-name="Сгруппировать 4450" transform="translate(-0.96 1410.365)">
      <g id="Сгруппировать_4087" data-name="Сгруппировать 4087" transform="translate(18.612 -1410.365)" clip-path="url(#clip-path)">
        <g id="Сгруппировать_4086" data-name="Сгруппировать 4086" transform="translate(-43.009 -54.033)" clip-path="url(#clip-path-2)">
          <path id="Контур_3575" data-name="Контур 3575" d="M95.481-1415.365H579.889v250.753H95.481Z" transform="translate(-53.359 1468.511)" fill="#ebf3f9"/>
        </g>
      </g>
      <g id="Сгруппировать_4089" data-name="Сгруппировать 4089" transform="translate(300.098 -1204.695)" clip-path="url(#clip-path-3)">
        <g id="Сгруппировать_4088" data-name="Сгруппировать 4088" transform="translate(-324.494 -259.703)" clip-path="url(#clip-path-2)">
          <path id="Контур_3578" data-name="Контур 3578" d="M1682.48-255.81h5.623v45.054h-5.623Z" transform="translate(-1358.872 514.626)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4091" data-name="Сгруппировать 4091" transform="translate(316.845 -1204.695)" clip-path="url(#clip-path-5)">
        <g id="Сгруппировать_4090" data-name="Сгруппировать 4090" transform="translate(-341.242 -259.703)" clip-path="url(#clip-path-2)">
          <path id="Контур_3581" data-name="Контур 3581" d="M1776.9-255.81h5.623v45.054H1776.9Z" transform="translate(-1436.545 514.626)" fill="#94c0f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4093" data-name="Сгруппировать 4093" transform="translate(273.072 -1204.695)" clip-path="url(#clip-path-7)">
        <g id="Сгруппировать_4092" data-name="Сгруппировать 4092" transform="translate(-297.468 -259.703)" clip-path="url(#clip-path-2)">
          <path id="Контур_3584" data-name="Контур 3584" d="M1530.109-255.81h5.625v45.054h-5.625Z" transform="translate(-1233.527 514.626)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4095" data-name="Сгруппировать 4095" transform="translate(282.552 -1245.19)" clip-path="url(#clip-path-9)">
        <g id="Сгруппировать_4094" data-name="Сгруппировать 4094" transform="translate(-306.949 -219.208)" clip-path="url(#clip-path-2)">
          <path id="Контур_3587" data-name="Контур 3587" d="M1583.56-484.12h41.114v41.089H1583.56Z" transform="translate(-1277.498 702.441)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4097" data-name="Сгруппировать 4097" transform="translate(278.813 -1206.983)" clip-path="url(#clip-path-11)">
        <g id="Сгруппировать_4096" data-name="Сгруппировать 4096" transform="translate(-303.21 -257.415)" clip-path="url(#clip-path-2)">
          <path id="Контур_3590" data-name="Контур 3590" d="M1562.48-268.71h44.853v6.348H1562.48Z" transform="translate(-1260.157 525.238)" fill="#94c0f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4099" data-name="Сгруппировать 4099" transform="translate(271.933 -1206.983)" clip-path="url(#clip-path-13)">
        <g id="Сгруппировать_4098" data-name="Сгруппировать 4098" transform="translate(-296.33 -257.415)" clip-path="url(#clip-path-2)">
          <path id="Контур_3593" data-name="Контур 3593" d="M1523.69-268.71h36.368v6.348H1523.69Z" transform="translate(-1228.247 525.238)" fill="#c9e4ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4101" data-name="Сгруппировать 4101" transform="translate(223.901 -1359.491)" clip-path="url(#clip-path-15)">
        <g id="Сгруппировать_4100" data-name="Сгруппировать 4100" transform="translate(-248.298 -104.907)" clip-path="url(#clip-path-2)">
          <path id="Контур_3596" data-name="Контур 3596" d="M1252.89-1128.539h122.124v105.666H1252.89Z" transform="translate(-1005.479 1232.559)" fill="#94c0f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4103" data-name="Сгруппировать 4103" transform="translate(226.191 -1357.76)" clip-path="url(#clip-path-17)">
        <g id="Сгруппировать_4102" data-name="Сгруппировать 4102" transform="translate(-250.588 -106.638)" clip-path="url(#clip-path-2)">
          <path id="Контур_3599" data-name="Контур 3599" d="M1265.8-1118.78h117.493v101.864H1265.8Z" transform="translate(-1016.099 1224.531)" fill="#dfecfa"/>
        </g>
      </g>
      <g id="Сгруппировать_4105" data-name="Сгруппировать 4105" transform="translate(241.28 -1349.752)" clip-path="url(#clip-path-19)">
        <g id="Сгруппировать_4104" data-name="Сгруппировать 4104" transform="translate(-265.677 -114.646)" clip-path="url(#clip-path-2)">
          <path id="Контур_3602" data-name="Контур 3602" d="M1350.87-1073.632H1378v37.314h-27.13Z" transform="translate(-1086.08 1187.391)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4107" data-name="Сгруппировать 4107" transform="translate(241.28 -1349.752)" clip-path="url(#clip-path-21)">
        <g id="Сгруппировать_4106" data-name="Сгруппировать 4106" transform="translate(-265.677 -114.646)" clip-path="url(#clip-path-2)">
          <path id="Контур_3605" data-name="Контур 3605" d="M1350.87-1073.632h6.073v5.65h-6.073Z" transform="translate(-1086.08 1187.391)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4109" data-name="Сгруппировать 4109" transform="translate(249.898 -1346.67)" clip-path="url(#clip-path-23)">
        <g id="Сгруппировать_4108" data-name="Сгруппировать 4108" transform="translate(-274.295 -117.728)" clip-path="url(#clip-path-2)">
          <path id="Контур_3608" data-name="Контур 3608" d="M1399.46-1056.256h9.894v2.16h-9.894Z" transform="translate(-1126.052 1173.097)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4111" data-name="Сгруппировать 4111" transform="translate(247.988 -1345.51)" clip-path="url(#clip-path-25)">
        <g id="Сгруппировать_4110" data-name="Сгруппировать 4110" transform="translate(-272.385 -118.888)" clip-path="url(#clip-path-2)">
          <path id="Контур_3611" data-name="Контур 3611" d="M1388.69-1049.716H1402.4v2.16H1388.69Z" transform="translate(-1117.192 1167.717)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4113" data-name="Сгруппировать 4113" transform="translate(244.281 -1338.061)" clip-path="url(#clip-path-27)">
        <g id="Сгруппировать_4112" data-name="Сгруппировать 4112" transform="translate(-268.678 -126.337)" clip-path="url(#clip-path-2)">
          <path id="Контур_3614" data-name="Контур 3614" d="M1367.79-1007.717h21.6v2.271h-21.6Z" transform="translate(-1099.999 1133.167)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4115" data-name="Сгруппировать 4115" transform="translate(244.281 -1336.551)" clip-path="url(#clip-path-29)">
        <g id="Сгруппировать_4114" data-name="Сгруппировать 4114" transform="translate(-268.678 -127.847)" clip-path="url(#clip-path-2)">
          <path id="Контур_3617" data-name="Контур 3617" d="M1367.79-999.206h21.6v2.271h-21.6Z" transform="translate(-1099.999 1126.166)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4117" data-name="Сгруппировать 4117" transform="translate(244.281 -1335.041)" clip-path="url(#clip-path-31)">
        <g id="Сгруппировать_4116" data-name="Сгруппировать 4116" transform="translate(-268.678 -129.357)" clip-path="url(#clip-path-2)">
          <path id="Контур_3620" data-name="Контур 3620" d="M1367.79-990.694h21.6v2.271h-21.6Z" transform="translate(-1099.999 1119.164)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4119" data-name="Сгруппировать 4119" transform="translate(244.281 -1333.532)" clip-path="url(#clip-path-33)">
        <g id="Сгруппировать_4118" data-name="Сгруппировать 4118" transform="translate(-268.678 -130.866)" clip-path="url(#clip-path-2)">
          <path id="Контур_3623" data-name="Контур 3623" d="M1367.79-982.183h21.6v2.271h-21.6Z" transform="translate(-1099.999 1112.163)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4121" data-name="Сгруппировать 4121" transform="translate(244.281 -1317.376)" clip-path="url(#clip-path-35)">
        <g id="Сгруппировать_4120" data-name="Сгруппировать 4120" transform="translate(-268.678 -147.022)" clip-path="url(#clip-path-2)">
          <path id="Контур_3626" data-name="Контур 3626" d="M1367.79-891.1h21.6v2.271h-21.6Z" transform="translate(-1099.999 1037.234)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4123" data-name="Сгруппировать 4123" transform="translate(270.558 -1305.821)" clip-path="url(#clip-path-37)">
        <g id="Сгруппировать_4122" data-name="Сгруппировать 4122" transform="translate(-294.955 -158.577)" clip-path="url(#clip-path-2)">
          <path id="Контур_3629" data-name="Контур 3629" d="M1515.94-825.953h27.132v37.314H1515.94Z" transform="translate(-1221.872 983.643)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4125" data-name="Сгруппировать 4125" transform="translate(270.558 -1305.821)" clip-path="url(#clip-path-39)">
        <g id="Сгруппировать_4124" data-name="Сгруппировать 4124" transform="translate(-294.955 -158.577)" clip-path="url(#clip-path-2)">
          <path id="Контур_3632" data-name="Контур 3632" d="M1515.94-825.953h6.075v5.65h-6.075Z" transform="translate(-1221.872 983.643)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4127" data-name="Сгруппировать 4127" transform="translate(279.179 -1302.739)" clip-path="url(#clip-path-41)">
        <g id="Сгруппировать_4126" data-name="Сгруппировать 4126" transform="translate(-303.575 -161.659)" clip-path="url(#clip-path-2)">
          <path id="Контур_3635" data-name="Контур 3635" d="M1564.54-808.577h9.892v2.16h-9.892Z" transform="translate(-1261.851 969.349)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4129" data-name="Сгруппировать 4129" transform="translate(277.267 -1301.579)" clip-path="url(#clip-path-43)">
        <g id="Сгруппировать_4128" data-name="Сгруппировать 4128" transform="translate(-301.663 -162.819)" clip-path="url(#clip-path-2)">
          <path id="Контур_3638" data-name="Контур 3638" d="M1553.76-802.038h13.716v2.16H1553.76Z" transform="translate(-1252.983 963.97)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4131" data-name="Сгруппировать 4131" transform="translate(273.561 -1294.13)" clip-path="url(#clip-path-45)">
        <g id="Сгруппировать_4130" data-name="Сгруппировать 4130" transform="translate(-297.958 -170.268)" clip-path="url(#clip-path-2)">
          <path id="Контур_3641" data-name="Контур 3641" d="M1532.87-760.038h21.6v2.271h-21.6Z" transform="translate(-1235.799 929.419)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4133" data-name="Сгруппировать 4133" transform="translate(273.561 -1292.62)" clip-path="url(#clip-path-47)">
        <g id="Сгруппировать_4132" data-name="Сгруппировать 4132" transform="translate(-297.958 -171.778)" clip-path="url(#clip-path-2)">
          <path id="Контур_3644" data-name="Контур 3644" d="M1532.87-751.527h21.6v2.271h-21.6Z" transform="translate(-1235.799 922.418)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4135" data-name="Сгруппировать 4135" transform="translate(273.561 -1291.11)" clip-path="url(#clip-path-49)">
        <g id="Сгруппировать_4134" data-name="Сгруппировать 4134" transform="translate(-297.958 -173.288)" clip-path="url(#clip-path-2)">
          <path id="Контур_3647" data-name="Контур 3647" d="M1532.87-743.015h21.6v2.271h-21.6Z" transform="translate(-1235.799 915.416)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4137" data-name="Сгруппировать 4137" transform="translate(273.561 -1289.601)" clip-path="url(#clip-path-51)">
        <g id="Сгруппировать_4136" data-name="Сгруппировать 4136" transform="translate(-297.958 -174.797)" clip-path="url(#clip-path-2)">
          <path id="Контур_3650" data-name="Контур 3650" d="M1532.87-734.5h21.6v2.271h-21.6Z" transform="translate(-1235.799 908.413)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4139" data-name="Сгруппировать 4139" transform="translate(273.561 -1284.955)" clip-path="url(#clip-path-53)">
        <g id="Сгруппировать_4138" data-name="Сгруппировать 4138" transform="translate(-297.958 -179.443)" clip-path="url(#clip-path-2)">
          <path id="Контур_3653" data-name="Контур 3653" d="M1532.87-708.31h21.6v2.27h-21.6Z" transform="translate(-1235.799 886.866)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4141" data-name="Сгруппировать 4141" transform="translate(273.561 -1283.445)" clip-path="url(#clip-path-55)">
        <g id="Сгруппировать_4140" data-name="Сгруппировать 4140" transform="translate(-297.958 -180.953)" clip-path="url(#clip-path-2)">
          <path id="Контур_3656" data-name="Контур 3656" d="M1532.87-699.8h21.6v2.27h-21.6Z" transform="translate(-1235.799 879.866)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4143" data-name="Сгруппировать 4143" transform="translate(273.561 -1281.695)" clip-path="url(#clip-path-57)">
        <g id="Сгруппировать_4142" data-name="Сгруппировать 4142" transform="translate(-297.958 -182.703)" clip-path="url(#clip-path-2)">
          <path id="Контур_3659" data-name="Контур 3659" d="M1532.87-689.93h21.6v2.27h-21.6Z" transform="translate(-1235.799 871.746)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4145" data-name="Сгруппировать 4145" transform="translate(296.224 -1349.752)" clip-path="url(#clip-path-59)">
        <g id="Сгруппировать_4144" data-name="Сгруппировать 4144" transform="translate(-320.621 -114.646)" clip-path="url(#clip-path-2)">
          <path id="Контур_3662" data-name="Контур 3662" d="M1660.64-1073.632h15.775v14.118H1660.64Z" transform="translate(-1340.906 1187.391)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4147" data-name="Сгруппировать 4147" transform="translate(302.613 -1348.895)" clip-path="url(#clip-path-61)">
        <g id="Сгруппировать_4146" data-name="Сгруппировать 4146" transform="translate(-327.01 -115.503)" clip-path="url(#clip-path-2)">
          <path id="Контур_3665" data-name="Контур 3665" d="M1696.66-1068.8h3v3h-3Z" transform="translate(-1370.537 1183.419)" fill="#ffa312"/>
        </g>
      </g>
      <g id="Сгруппировать_4149" data-name="Сгруппировать 4149" transform="translate(295.811 -1333.529)" clip-path="url(#clip-path-63)">
        <g id="Сгруппировать_4148" data-name="Сгруппировать 4148" transform="translate(-320.207 -130.869)" clip-path="url(#clip-path-2)">
          <path id="Контур_3668" data-name="Контур 3668" d="M1658.31-982.167h16.6V-967.1h-16.6Z" transform="translate(-1338.99 1112.149)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4151" data-name="Сгруппировать 4151" transform="translate(302.338 -1331.425)" clip-path="url(#clip-path-65)">
        <g id="Сгруппировать_4150" data-name="Сгруппировать 4150" transform="translate(-326.735 -132.973)" clip-path="url(#clip-path-2)">
          <path id="Контур_3671" data-name="Контур 3671" d="M1695.112-970.3h3v3h-3Z" transform="translate(-1369.264 1102.39)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4153" data-name="Сгруппировать 4153" transform="translate(249.281 -1288.051)" clip-path="url(#clip-path-67)">
        <g id="Сгруппировать_4152" data-name="Сгруппировать 4152" transform="translate(-273.678 -176.347)" clip-path="url(#clip-path-2)">
          <path id="Контур_3674" data-name="Контур 3674" d="M1395.98-725.767h16.6V-710.7h-16.6Z" transform="translate(-1123.189 901.227)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4155" data-name="Сгруппировать 4155" transform="translate(255.809 -1285.948)" clip-path="url(#clip-path-69)">
        <g id="Сгруппировать_4154" data-name="Сгруппировать 4154" transform="translate(-280.206 -178.45)" clip-path="url(#clip-path-2)">
          <path id="Контур_3677" data-name="Контур 3677" d="M1432.782-713.908h3v3h-3Z" transform="translate(-1153.463 891.472)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4157" data-name="Сгруппировать 4157" transform="translate(231.674 -1279.192)" clip-path="url(#clip-path-71)">
        <g id="Сгруппировать_4156" data-name="Сгруппировать 4156" transform="translate(-256.07 -185.206)" clip-path="url(#clip-path-2)">
          <path id="Контур_3680" data-name="Контур 3680" d="M1296.71-675.82h15.9v14.26h-15.9Z" transform="translate(-1041.526 860.139)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4159" data-name="Сгруппировать 4159" transform="translate(238.084 -1277.498)" clip-path="url(#clip-path-73)">
        <g id="Сгруппировать_4158" data-name="Сгруппировать 4158" transform="translate(-262.481 -186.9)" clip-path="url(#clip-path-2)">
          <path id="Контур_3683" data-name="Контур 3683" d="M1332.85-666.27h3v3h-3Z" transform="translate(-1071.256 852.283)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4161" data-name="Сгруппировать 4161" transform="translate(315.023 -1326.073)" clip-path="url(#clip-path-75)">
        <g id="Сгруппировать_4160" data-name="Сгруппировать 4160" transform="translate(-339.42 -138.326)" clip-path="url(#clip-path-2)">
          <path id="Контур_3686" data-name="Контур 3686" d="M1766.63-940.129h17.728v16.389H1766.63Z" transform="translate(-1428.097 1077.568)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4163" data-name="Сгруппировать 4163" transform="translate(323.259 -1323.728)" clip-path="url(#clip-path-77)">
        <g id="Сгруппировать_4162" data-name="Сгруппировать 4162" transform="translate(-347.656 -140.67)" clip-path="url(#clip-path-2)">
          <path id="Контур_3689" data-name="Контур 3689" d="M1813.063-926.909h2.84v2.84h-2.84Z" transform="translate(-1466.294 1066.693)" fill="#78acea"/>
        </g>
      </g>
      <g id="Сгруппировать_4165" data-name="Сгруппировать 4165" transform="translate(274.046 -1333.774)" clip-path="url(#clip-path-79)">
        <g id="Сгруппировать_4164" data-name="Сгруппировать 4164" transform="translate(-298.442 -130.624)" clip-path="url(#clip-path-2)">
          <path id="Контур_3692" data-name="Контур 3692" d="M1535.6-983.548h17.022V-968H1535.6Z" transform="translate(-1238.044 1113.285)" fill="#edf6ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4167" data-name="Сгруппировать 4167" transform="translate(280.653 -1331.891)" clip-path="url(#clip-path-81)">
        <g id="Сгруппировать_4166" data-name="Сгруппировать 4166" transform="translate(-305.05 -132.507)" clip-path="url(#clip-path-2)">
          <path id="Контур_3695" data-name="Контур 3695" d="M1572.852-972.933h2.839v2.84h-2.839Z" transform="translate(-1268.689 1104.553)" fill="#78acea"/>
        </g>
      </g>
      <g id="Сгруппировать_4169" data-name="Сгруппировать 4169" transform="translate(237.928 -1304.594)" clip-path="url(#clip-path-83)">
        <g id="Сгруппировать_4168" data-name="Сгруппировать 4168" transform="translate(-262.325 -159.804)" clip-path="url(#clip-path-2)">
          <path id="Контур_3698" data-name="Контур 3698" d="M1331.97-819.033h18.042v16.767H1331.97Z" transform="translate(-1070.532 977.95)" fill="#b9e8e2"/>
        </g>
      </g>
      <g id="Сгруппировать_4171" data-name="Сгруппировать 4171" transform="translate(244.588 -1302.036)" clip-path="url(#clip-path-85)">
        <g id="Сгруппировать_4170" data-name="Сгруппировать 4170" transform="translate(-268.985 -162.362)" clip-path="url(#clip-path-2)">
          <path id="Контур_3701" data-name="Контур 3701" d="M1369.522-804.612h2.84v2.84h-2.84Z" transform="translate(-1101.424 966.087)" fill="#ffa312"/>
        </g>
      </g>
      <g id="Сгруппировать_4173" data-name="Сгруппировать 4173" transform="translate(275.007 -1349.752)" clip-path="url(#clip-path-87)">
        <g id="Сгруппировать_4172" data-name="Сгруппировать 4172" transform="translate(-299.404 -114.646)" clip-path="url(#clip-path-2)">
          <path id="Контур_3704" data-name="Контур 3704" d="M1541.02-1073.632h15.1v14.118h-15.1Z" transform="translate(-1242.503 1187.391)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4175" data-name="Сгруппировать 4175" transform="translate(274.936 -1349.752)" clip-path="url(#clip-path-89)">
        <g id="Сгруппировать_4174" data-name="Сгруппировать 4174" transform="translate(-299.333 -114.646)" clip-path="url(#clip-path-2)">
          <path id="Контур_3707" data-name="Контур 3707" d="M1540.62-1073.632h5.534v5.164h-5.534Z" transform="translate(-1242.174 1187.391)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4177" data-name="Сгруппировать 4177" transform="translate(281.719 -1349.048)" clip-path="url(#clip-path-91)">
        <g id="Сгруппировать_4176" data-name="Сгруппировать 4176" transform="translate(-306.115 -115.35)" clip-path="url(#clip-path-2)">
          <path id="Контур_3710" data-name="Контур 3710" d="M1578.86-1069.664h2.65v2.65h-2.65Z" transform="translate(-1273.631 1184.127)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4179" data-name="Сгруппировать 4179" transform="translate(313.945 -1276.453)" clip-path="url(#clip-path-93)">
        <g id="Сгруппировать_4178" data-name="Сгруппировать 4178" transform="translate(-338.342 -187.945)" clip-path="url(#clip-path-2)">
          <path id="Контур_3713" data-name="Контур 3713" d="M1760.55-660.38h15.1v14.119h-15.1Z" transform="translate(-1423.095 847.438)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4181" data-name="Сгруппировать 4181" transform="translate(313.874 -1276.453)" clip-path="url(#clip-path-95)">
        <g id="Сгруппировать_4180" data-name="Сгруппировать 4180" transform="translate(-338.271 -187.945)" clip-path="url(#clip-path-2)">
          <path id="Контур_3716" data-name="Контур 3716" d="M1760.15-660.38h5.534v5.163h-5.534Z" transform="translate(-1422.766 847.438)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4183" data-name="Сгруппировать 4183" transform="translate(320.657 -1275.749)" clip-path="url(#clip-path-97)">
        <g id="Сгруппировать_4182" data-name="Сгруппировать 4182" transform="translate(-345.053 -188.649)" clip-path="url(#clip-path-2)">
          <path id="Контур_3719" data-name="Контур 3719" d="M1798.39-656.41h2.65v2.65h-2.65Z" transform="translate(-1454.223 844.172)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4185" data-name="Сгруппировать 4185" transform="translate(320.025 -1294.223)" clip-path="url(#clip-path-99)">
        <g id="Сгруппировать_4184" data-name="Сгруппировать 4184" transform="translate(-344.422 -170.175)" clip-path="url(#clip-path-2)">
          <path id="Контур_3722" data-name="Контур 3722" d="M1794.83-760.565h15.1v14.118h-15.1Z" transform="translate(-1451.295 929.853)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4187" data-name="Сгруппировать 4187" transform="translate(319.952 -1294.223)" clip-path="url(#clip-path-101)">
        <g id="Сгруппировать_4186" data-name="Сгруппировать 4186" transform="translate(-344.349 -170.175)" clip-path="url(#clip-path-2)">
          <path id="Контур_3725" data-name="Контур 3725" d="M1794.42-760.565h5.536v5.164h-5.536Z" transform="translate(-1450.958 929.853)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4189" data-name="Сгруппировать 4189" transform="translate(326.737 -1293.52)" clip-path="url(#clip-path-103)">
        <g id="Сгруппировать_4188" data-name="Сгруппировать 4188" transform="translate(-351.134 -170.879)" clip-path="url(#clip-path-2)">
          <path id="Контур_3728" data-name="Контур 3728" d="M1832.67-756.6h2.65v2.65h-2.65Z" transform="translate(-1482.423 926.589)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4191" data-name="Сгруппировать 4191" transform="translate(302.437 -1294.223)" clip-path="url(#clip-path-105)">
        <g id="Сгруппировать_4190" data-name="Сгруппировать 4190" transform="translate(-326.834 -170.175)" clip-path="url(#clip-path-2)">
          <path id="Контур_3731" data-name="Контур 3731" d="M1695.67-760.565h15.1v14.118h-15.1Z" transform="translate(-1369.723 929.853)" fill="#b9e8e2"/>
        </g>
      </g>
      <g id="Сгруппировать_4193" data-name="Сгруппировать 4193" transform="translate(302.366 -1294.223)" clip-path="url(#clip-path-107)">
        <g id="Сгруппировать_4192" data-name="Сгруппировать 4192" transform="translate(-326.763 -170.175)" clip-path="url(#clip-path-2)">
          <path id="Контур_3734" data-name="Контур 3734" d="M1695.27-760.565h5.534v5.164h-5.534Z" transform="translate(-1369.394 929.853)" fill="#a0d5ce"/>
        </g>
      </g>
      <g id="Сгруппировать_4195" data-name="Сгруппировать 4195" transform="translate(309.149 -1293.52)" clip-path="url(#clip-path-109)">
        <g id="Сгруппировать_4194" data-name="Сгруппировать 4194" transform="translate(-333.546 -170.879)" clip-path="url(#clip-path-2)">
          <path id="Контур_3737" data-name="Контур 3737" d="M1733.51-756.6h2.652v2.65h-2.652Z" transform="translate(-1400.851 926.589)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_4197" data-name="Сгруппировать 4197" transform="translate(323.785 -1352.842)" clip-path="url(#clip-path-111)">
        <g id="Сгруппировать_4196" data-name="Сгруппировать 4196" transform="translate(-348.182 -111.556)" clip-path="url(#clip-path-2)">
          <path id="Контур_3740" data-name="Контур 3740" d="M1816.03-1091.054h15.1v14.118h-15.1Z" transform="translate(-1468.735 1201.723)" fill="#b9e8e2"/>
        </g>
      </g>
      <g id="Сгруппировать_4199" data-name="Сгруппировать 4199" transform="translate(323.714 -1352.842)" clip-path="url(#clip-path-113)">
        <g id="Сгруппировать_4198" data-name="Сгруппировать 4198" transform="translate(-348.111 -111.556)" clip-path="url(#clip-path-2)">
          <path id="Контур_3743" data-name="Контур 3743" d="M1815.63-1091.054h5.534v5.164h-5.534Z" transform="translate(-1468.406 1201.723)" fill="#a0d5ce"/>
        </g>
      </g>
      <g id="Сгруппировать_4201" data-name="Сгруппировать 4201" transform="translate(330.497 -1352.138)" clip-path="url(#clip-path-115)">
        <g id="Сгруппировать_4200" data-name="Сгруппировать 4200" transform="translate(-354.894 -112.26)" clip-path="url(#clip-path-2)">
          <path id="Контур_3746" data-name="Контур 3746" d="M1853.87-1087.086h2.65v2.65h-2.65Z" transform="translate(-1499.863 1198.459)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_4203" data-name="Сгруппировать 4203" transform="translate(177.858 -1390.703)" clip-path="url(#clip-path-117)">
        <g id="Сгруппировать_4202" data-name="Сгруппировать 4202" transform="translate(-202.255 -73.696)" clip-path="url(#clip-path-2)">
          <path id="Контур_3749" data-name="Контур 3749" d="M993.3-1304.509h31.355v30.473H993.3Z" transform="translate(-791.932 1377.318)" fill="#c0d7fa"/>
        </g>
      </g>
      <g id="Сгруппировать_4205" data-name="Сгруппировать 4205" transform="translate(179.14 -1389.459)" clip-path="url(#clip-path-119)">
        <g id="Сгруппировать_4204" data-name="Сгруппировать 4204" transform="translate(-203.537 -74.939)" clip-path="url(#clip-path-2)">
          <path id="Контур_3752" data-name="Контур 3752" d="M1000.53-1297.5h28.792v27.986H1000.53Z" transform="translate(-797.88 1371.552)" fill="#dfebfc"/>
        </g>
      </g>
      <g id="Сгруппировать_4207" data-name="Сгруппировать 4207" transform="translate(192.037 -1387.453)" clip-path="url(#clip-path-121)">
        <g id="Сгруппировать_4206" data-name="Сгруппировать 4206" transform="translate(-216.434 -76.945)" clip-path="url(#clip-path-2)">
          <path id="Контур_3755" data-name="Контур 3755" d="M1073.24-1286.187h3v3h-3Z" transform="translate(-857.693 1362.245)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4209" data-name="Сгруппировать 4209" transform="translate(196.904 -1386.289)" clip-path="url(#clip-path-123)">
        <g id="Сгруппировать_4208" data-name="Сгруппировать 4208" transform="translate(-221.3 -78.109)" clip-path="url(#clip-path-2)">
          <path id="Контур_3758" data-name="Контур 3758" d="M1100.679-1279.627h3v3h-3Z" transform="translate(-880.265 1356.849)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4211" data-name="Сгруппировать 4211" transform="translate(200.936 -1382.706)" clip-path="url(#clip-path-125)">
        <g id="Сгруппировать_4210" data-name="Сгруппировать 4210" transform="translate(-225.333 -81.692)" clip-path="url(#clip-path-2)">
          <path id="Контур_3761" data-name="Контур 3761" d="M1123.414-1259.427h3v3h-3Z" transform="translate(-898.968 1340.232)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4213" data-name="Сгруппировать 4213" transform="translate(202.702 -1377.399)" clip-path="url(#clip-path-127)">
        <g id="Сгруппировать_4212" data-name="Сгруппировать 4212" transform="translate(-227.099 -86.999)" clip-path="url(#clip-path-2)">
          <path id="Контур_3764" data-name="Контур 3764" d="M1133.37-1229.507h3v3h-3Z" transform="translate(-907.158 1315.619)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4215" data-name="Сгруппировать 4215" transform="translate(201.198 -1371.534)" clip-path="url(#clip-path-129)">
        <g id="Сгруппировать_4214" data-name="Сгруппировать 4214" transform="translate(-225.594 -92.864)" clip-path="url(#clip-path-2)">
          <path id="Контур_3767" data-name="Контур 3767" d="M1124.887-1196.437h3v3h-3Z" transform="translate(-900.18 1288.414)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4217" data-name="Сгруппировать 4217" transform="translate(197.35 -1367.676)" clip-path="url(#clip-path-131)">
        <g id="Сгруппировать_4216" data-name="Сгруппировать 4216" transform="translate(-221.747 -96.722)" clip-path="url(#clip-path-2)">
          <path id="Контур_3770" data-name="Контур 3770" d="M1103.2-1174.686h3v3h-3Z" transform="translate(-882.338 1270.521)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4219" data-name="Сгруппировать 4219" transform="translate(192.037 -1366.12)" clip-path="url(#clip-path-133)">
        <g id="Сгруппировать_4218" data-name="Сгруппировать 4218" transform="translate(-216.434 -98.278)" clip-path="url(#clip-path-2)">
          <path id="Контур_3773" data-name="Контур 3773" d="M1073.24-1165.917h3v3h-3Z" transform="translate(-857.693 1263.308)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4221" data-name="Сгруппировать 4221" transform="translate(187.149 -1386.289)" clip-path="url(#clip-path-135)">
        <g id="Сгруппировать_4220" data-name="Сгруппировать 4220" transform="translate(-211.546 -78.109)" clip-path="url(#clip-path-2)">
          <path id="Контур_3776" data-name="Контур 3776" d="M1045.682-1279.627h3v3h-3Z" transform="translate(-835.023 1356.849)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4223" data-name="Сгруппировать 4223" transform="translate(183.117 -1382.706)" clip-path="url(#clip-path-137)">
        <g id="Сгруппировать_4222" data-name="Сгруппировать 4222" transform="translate(-207.514 -81.692)" clip-path="url(#clip-path-2)">
          <path id="Контур_3779" data-name="Контур 3779" d="M1022.951-1259.427h3v3h-3Z" transform="translate(-816.324 1340.232)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4225" data-name="Сгруппировать 4225" transform="translate(181.35 -1377.399)" clip-path="url(#clip-path-139)">
        <g id="Сгруппировать_4224" data-name="Сгруппировать 4224" transform="translate(-205.747 -86.999)" clip-path="url(#clip-path-2)">
          <path id="Контур_3782" data-name="Контур 3782" d="M1012.99-1229.507h3v3h-3Z" transform="translate(-808.13 1315.619)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4227" data-name="Сгруппировать 4227" transform="translate(182.856 -1371.534)" clip-path="url(#clip-path-141)">
        <g id="Сгруппировать_4226" data-name="Сгруппировать 4226" transform="translate(-207.253 -92.864)" clip-path="url(#clip-path-2)">
          <path id="Контур_3785" data-name="Контур 3785" d="M1021.478-1196.437h3v3h-3Z" transform="translate(-815.112 1288.414)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4229" data-name="Сгруппировать 4229" transform="translate(186.703 -1367.676)" clip-path="url(#clip-path-143)">
        <g id="Сгруппировать_4228" data-name="Сгруппировать 4228" transform="translate(-211.1 -96.722)" clip-path="url(#clip-path-2)">
          <path id="Контур_3788" data-name="Контур 3788" d="M1043.168-1174.686h3v3h-3Z" transform="translate(-832.955 1270.521)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4231" data-name="Сгруппировать 4231" transform="translate(192.484 -1377.399)" clip-path="url(#clip-path-145)">
        <g id="Сгруппировать_4230" data-name="Сгруппировать 4230" transform="translate(-216.881 -86.999)" clip-path="url(#clip-path-2)">
          <path id="Контур_3791" data-name="Контур 3791" d="M1075.76-1229.507h3v3h-3Z" transform="translate(-859.766 1315.619)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4233" data-name="Сгруппировать 4233" transform="translate(193.012 -1384.442)" clip-path="url(#clip-path-147)">
        <g id="Сгруппировать_4232" data-name="Сгруппировать 4232" transform="translate(-217.409 -79.956)" clip-path="url(#clip-path-2)">
          <path id="Контур_3794" data-name="Контур 3794" d="M1078.74-1269.212h1.942v9.429h-1.942Z" transform="translate(-862.218 1348.281)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4235" data-name="Сгруппировать 4235" transform="translate(192.863 -1380.181)" clip-path="url(#clip-path-149)">
        <g id="Сгруппировать_4234" data-name="Сгруппировать 4234" transform="translate(-217.26 -84.217)" clip-path="url(#clip-path-2)">
          <path id="Контур_3797" data-name="Контур 3797" d="M1077.9-1245.188h6.142v5.354H1077.9Z" transform="translate(-861.527 1328.518)" fill="#b7d2f3"/>
        </g>
      </g>
      <g id="Сгруппировать_4237" data-name="Сгруппировать 4237" transform="translate(345.524 -1174.267)" clip-path="url(#clip-path-151)">
        <g id="Сгруппировать_4236" data-name="Сгруппировать 4236" transform="translate(-369.921 -290.131)" clip-path="url(#clip-path-2)">
          <path id="Контур_3800" data-name="Контур 3800" d="M1938.594-84.26h20.441v14.885h-20.441Z" transform="translate(-1569.56 373.504)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4239" data-name="Сгруппировать 4239" transform="translate(345.524 -1164.641)" clip-path="url(#clip-path-153)">
        <g id="Сгруппировать_4238" data-name="Сгруппировать 4238" transform="translate(-369.921 -299.757)" clip-path="url(#clip-path-2)">
          <path id="Контур_3803" data-name="Контур 3803" d="M1938.594-29.99h17.639v5.259h-17.639Z" transform="translate(-1569.56 328.86)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4241" data-name="Сгруппировать 4241" transform="translate(380.344 -1177.297)" clip-path="url(#clip-path-155)">
        <g id="Сгруппировать_4240" data-name="Сгруппировать 4240" transform="translate(-404.741 -287.101)" clip-path="url(#clip-path-2)">
          <path id="Контур_3806" data-name="Контур 3806" d="M2134.9-101.34h17.639v18.267H2134.9Z" transform="translate(-1731.05 387.554)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4243" data-name="Сгруппировать 4243" transform="translate(380.344 -1164.299)" clip-path="url(#clip-path-157)">
        <g id="Сгруппировать_4242" data-name="Сгруппировать 4242" transform="translate(-404.741 -300.099)" clip-path="url(#clip-path-2)">
          <path id="Контур_3809" data-name="Контур 3809" d="M2134.9-28.06h17.639v5.27H2134.9Z" transform="translate(-1731.05 327.272)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4245" data-name="Сгруппировать 4245" transform="translate(379.698 -1309.333)" clip-path="url(#clip-path-159)">
        <g id="Сгруппировать_4244" data-name="Сгруппировать 4244" transform="translate(-404.095 -155.065)" clip-path="url(#clip-path-2)">
          <path id="Контур_3812" data-name="Контур 3812" d="M2131.262-845.755h29.16v46.965h-29.16Z" transform="translate(-1728.054 999.933)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_4247" data-name="Сгруппировать 4247" transform="translate(383.616 -1292.714)" clip-path="url(#clip-path-161)">
        <g id="Сгруппировать_4246" data-name="Сгруппировать 4246" transform="translate(-408.012 -171.684)" clip-path="url(#clip-path-2)">
          <path id="Контур_3815" data-name="Контур 3815" d="M2153.349-752.054h10.359v11.834h-10.359Z" transform="translate(-1746.224 922.851)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4249" data-name="Сгруппировать 4249" transform="translate(383.94 -1292.635)" clip-path="url(#clip-path-163)">
        <g id="Сгруппировать_4248" data-name="Сгруппировать 4248" transform="translate(-408.337 -171.763)" clip-path="url(#clip-path-2)">
          <path id="Контур_3818" data-name="Контур 3818" d="M2155.18-751.609h8.51v7.225h-8.51Z" transform="translate(-1747.729 922.485)" fill="#ec965d"/>
        </g>
      </g>
      <g id="Сгруппировать_4251" data-name="Сгруппировать 4251" transform="translate(356.451 -1246.473)" clip-path="url(#clip-path-165)">
        <g id="Сгруппировать_4250" data-name="Сгруппировать 4250" transform="translate(-380.847 -217.925)" clip-path="url(#clip-path-2)">
          <path id="Контур_3821" data-name="Контур 3821" d="M2000.194-491.35h40.652v79.239h-40.652Z" transform="translate(-1620.234 708.388)" fill="#2a4e95"/>
        </g>
      </g>
      <g id="Сгруппировать_4253" data-name="Сгруппировать 4253" transform="translate(373.6 -1286.746)" clip-path="url(#clip-path-167)">
        <g id="Сгруппировать_4252" data-name="Сгруппировать 4252" transform="translate(-397.997 -177.652)" clip-path="url(#clip-path-2)">
          <path id="Контур_3824" data-name="Контур 3824" d="M2096.883-718.41h32.768V-671.4h-32.768Z" transform="translate(-1699.773 895.175)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4255" data-name="Сгруппировать 4255" transform="translate(392.081 -1275.459)" clip-path="url(#clip-path-169)">
        <g id="Сгруппировать_4254" data-name="Сгруппировать 4254" transform="translate(-416.478 -188.939)" clip-path="url(#clip-path-2)">
          <path id="Контур_3827" data-name="Контур 3827" d="M2201.076-654.772h12.287v30.146h-12.287Z" transform="translate(-1785.485 842.825)" fill="#5f9aa8"/>
        </g>
      </g>
      <g id="Сгруппировать_4257" data-name="Сгруппировать 4257" transform="translate(379.71 -1304.46)" clip-path="url(#clip-path-171)">
        <g id="Сгруппировать_4256" data-name="Сгруппировать 4256" transform="translate(-404.107 -159.938)" clip-path="url(#clip-path-2)">
          <path id="Контур_3830" data-name="Контур 3830" d="M2131.33-818.28h13.77v16.825h-13.77Z" transform="translate(-1728.11 977.331)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4259" data-name="Сгруппировать 4259" transform="translate(383.011 -1305.692)" clip-path="url(#clip-path-173)">
        <g id="Сгруппировать_4258" data-name="Сгруппировать 4258" transform="translate(-407.408 -158.707)" clip-path="url(#clip-path-2)">
          <path id="Контур_3833" data-name="Контур 3833" d="M2149.94-825.222h13.338v10.8H2149.94Z" transform="translate(-1743.419 983.042)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_4261" data-name="Сгруппировать 4261" transform="translate(379.1 -1305.096)" clip-path="url(#clip-path-175)">
        <g id="Сгруппировать_4260" data-name="Сгруппировать 4260" transform="translate(-403.497 -159.302)" clip-path="url(#clip-path-2)">
          <path id="Контур_3836" data-name="Контур 3836" d="M2127.891-821.865h6.289v9.938h-6.289Z" transform="translate(-1725.281 980.28)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_4263" data-name="Сгруппировать 4263" transform="translate(364.433 -1251.583)" clip-path="url(#clip-path-177)">
        <g id="Сгруппировать_4262" data-name="Сгруппировать 4262" transform="translate(-388.83 -212.815)" clip-path="url(#clip-path-2)">
          <path id="Контур_3839" data-name="Контур 3839" d="M2045.2-520.16h11.142v15.58H2045.2Z" transform="translate(-1657.257 732.088)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4265" data-name="Сгруппировать 4265" transform="translate(367.599 -1284.976)" clip-path="url(#clip-path-179)">
        <g id="Сгруппировать_4264" data-name="Сгруппировать 4264" transform="translate(-391.996 -179.422)" clip-path="url(#clip-path-2)">
          <path id="Контур_3842" data-name="Контур 3842" d="M2063.05-708.43h14.179v38.681H2063.05Z" transform="translate(-1671.941 886.965)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_4267" data-name="Сгруппировать 4267" transform="translate(370.595 -1286.746)" clip-path="url(#clip-path-181)">
        <g id="Сгруппировать_4266" data-name="Сгруппировать 4266" transform="translate(-394.992 -177.652)" clip-path="url(#clip-path-2)">
          <path id="Контур_3845" data-name="Контур 3845" d="M2079.94-718.41h15.28v60.54h-15.28Z" transform="translate(-1685.835 895.175)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_4269" data-name="Сгруппировать 4269" transform="translate(360.659 -1230.809)" clip-path="url(#clip-path-183)">
        <g id="Сгруппировать_4268" data-name="Сгруппировать 4268" transform="translate(-385.056 -233.589)" clip-path="url(#clip-path-2)">
          <path id="Контур_3848" data-name="Контур 3848" d="M2023.92-403.04h33.008v63.583H2023.92Z" transform="translate(-1639.751 635.742)" fill="#1e3c6f"/>
        </g>
      </g>
      <g id="Сгруппировать_4271" data-name="Сгруппировать 4271" transform="translate(386.253 -1234.917)" clip-path="url(#clip-path-185)">
        <g id="Сгруппировать_4270" data-name="Сгруппировать 4270" transform="translate(-410.65 -229.481)" clip-path="url(#clip-path-2)">
          <path id="Контур_3851" data-name="Контур 3851" d="M2168.22-426.2h20.458v67.4H2168.22Z" transform="translate(-1758.457 654.794)" fill="#2a4e95"/>
        </g>
      </g>
      <g id="Сгруппировать_4273" data-name="Сгруппировать 4273" transform="translate(393.029 -1229.871)" clip-path="url(#clip-path-187)">
        <g id="Сгруппировать_4272" data-name="Сгруппировать 4272" transform="translate(-417.426 -234.527)" clip-path="url(#clip-path-2)">
          <path id="Контур_3854" data-name="Контур 3854" d="M2206.42-397.75h13.615v62.356H2206.42Z" transform="translate(-1789.881 631.39)" fill="#1e3c6f"/>
        </g>
      </g>
      <g id="Сгруппировать_4275" data-name="Сгруппировать 4275" transform="translate(385.761 -1286.487)" clip-path="url(#clip-path-189)">
        <g id="Сгруппировать_4274" data-name="Сгруппировать 4274" transform="translate(-410.157 -177.911)" clip-path="url(#clip-path-2)">
          <path id="Контур_3857" data-name="Контур 3857" d="M2165.443-716.95H2190.5v62.477h-25.055Z" transform="translate(-1756.172 893.974)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_4277" data-name="Сгруппировать 4277" transform="translate(379.095 -1286.487)" clip-path="url(#clip-path-191)">
        <g id="Сгруппировать_4276" data-name="Сгруппировать 4276" transform="translate(-403.491 -177.911)" clip-path="url(#clip-path-2)">
          <path id="Контур_3860" data-name="Контур 3860" d="M2127.86-716.95h27.329V-683.7H2127.86Z" transform="translate(-1725.255 893.974)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4279" data-name="Сгруппировать 4279" transform="translate(392.344 -1273.736)" clip-path="url(#clip-path-193)">
        <g id="Сгруппировать_4278" data-name="Сгруппировать 4278" transform="translate(-416.74 -190.662)" clip-path="url(#clip-path-2)">
          <path id="Контур_3863" data-name="Контур 3863" d="M2202.558-645.06h14.106v32.109h-14.106Z" transform="translate(-1786.704 834.835)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_4281" data-name="Сгруппировать 4281" transform="translate(391.173 -1286.487)" clip-path="url(#clip-path-195)">
        <g id="Сгруппировать_4280" data-name="Сгруппировать 4280" transform="translate(-415.569 -177.911)" clip-path="url(#clip-path-2)">
          <path id="Контур_3866" data-name="Контур 3866" d="M2195.955-716.95h15.735v34.134h-15.735Z" transform="translate(-1781.273 893.974)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_4283" data-name="Сгруппировать 4283" transform="translate(368.536 -1264.511)" clip-path="url(#clip-path-197)">
        <g id="Сгруппировать_4282" data-name="Сгруппировать 4282" transform="translate(-392.933 -199.887)" clip-path="url(#clip-path-2)">
          <path id="Контур_3869" data-name="Контур 3869" d="M2068.33-593.05h17.34v7.366h-17.34Z" transform="translate(-1676.284 792.05)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_4285" data-name="Сгруппировать 4285" transform="translate(367.44 -1259.793)" clip-path="url(#clip-path-199)">
        <g id="Сгруппировать_4284" data-name="Сгруппировать 4284" transform="translate(-391.836 -204.605)" clip-path="url(#clip-path-2)">
          <path id="Контур_3872" data-name="Контур 3872" d="M2062.15-566.45h3.141v2.648h-3.141Z" transform="translate(-1671.2 770.168)" fill="#a9453c"/>
        </g>
      </g>
      <g id="Сгруппировать_4287" data-name="Сгруппировать 4287" transform="translate(360.435 -1239.091)" clip-path="url(#clip-path-201)">
        <g id="Сгруппировать_4286" data-name="Сгруппировать 4286" transform="translate(-384.832 -225.307)" clip-path="url(#clip-path-2)">
          <path id="Контур_3875" data-name="Контур 3875" d="M2022.66-449.73h8.485v10.481h-8.485Z" transform="translate(-1638.715 674.151)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4289" data-name="Сгруппировать 4289" transform="translate(370.387 -1263.683)" clip-path="url(#clip-path-203)">
        <g id="Сгруппировать_4288" data-name="Сгруппировать 4288" transform="translate(-394.783 -200.715)" clip-path="url(#clip-path-2)">
          <path id="Контур_3878" data-name="Контур 3878" d="M2078.765-588.383h11.89v6.939h-11.89Z" transform="translate(-1684.868 788.211)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4291" data-name="Сгруппировать 4291" transform="translate(100.854 -1318.988)" clip-path="url(#clip-path-205)">
        <g id="Сгруппировать_4290" data-name="Сгруппировать 4290" transform="translate(-125.251 -145.41)" clip-path="url(#clip-path-2)">
          <path id="Контур_3881" data-name="Контур 3881" d="M559.156-900.186h38.31v42h-38.31Z" transform="translate(-434.792 1044.709)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_4293" data-name="Сгруппировать 4293" transform="translate(148.835 -1275.249)" clip-path="url(#clip-path-207)">
        <g id="Сгруппировать_4292" data-name="Сгруппировать 4292" transform="translate(-173.232 -189.149)" clip-path="url(#clip-path-2)">
          <path id="Контур_3884" data-name="Контур 3884" d="M829.67-653.587H841.1v5.968H829.67Z" transform="translate(-657.325 841.85)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4295" data-name="Сгруппировать 4295" transform="translate(125.63 -1291.063)" clip-path="url(#clip-path-209)">
        <g id="Сгруппировать_4294" data-name="Сгруппировать 4294" transform="translate(-150.026 -173.335)" clip-path="url(#clip-path-2)">
          <path id="Контур_3887" data-name="Контур 3887" d="M698.84-742.745h25.953v30.764H698.84Z" transform="translate(-549.7 915.194)" fill="#afd0ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4297" data-name="Сгруппировать 4297" transform="translate(127.799 -1272.642)" clip-path="url(#clip-path-211)">
        <g id="Сгруппировать_4296" data-name="Сгруппировать 4296" transform="translate(-152.196 -191.756)" clip-path="url(#clip-path-2)">
          <path id="Контур_3890" data-name="Контур 3890" d="M711.07-638.893h15.669v12.344H711.07Z" transform="translate(-559.761 829.762)" fill="#97b9f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4299" data-name="Сгруппировать 4299" transform="translate(89.564 -1175.037)" clip-path="url(#clip-path-213)">
        <g id="Сгруппировать_4298" data-name="Сгруппировать 4298" transform="translate(-113.961 -289.361)" clip-path="url(#clip-path-2)">
          <path id="Контур_3893" data-name="Контур 3893" d="M495.506-88.6h14.073v15.837H495.506Z" transform="translate(-382.432 377.074)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4301" data-name="Сгруппировать 4301" transform="translate(114.853 -1172.772)" clip-path="url(#clip-path-215)">
        <g id="Сгруппировать_4300" data-name="Сгруппировать 4300" transform="translate(-139.25 -291.626)" clip-path="url(#clip-path-2)">
          <path id="Контур_3896" data-name="Контур 3896" d="M638.082-75.83h17.9v13.049h-17.9Z" transform="translate(-499.719 366.569)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4303" data-name="Сгруппировать 4303" transform="translate(104.107 -1301.05)" clip-path="url(#clip-path-217)">
        <g id="Сгруппировать_4302" data-name="Сгруппировать 4302" transform="translate(-128.504 -163.348)" clip-path="url(#clip-path-2)">
          <path id="Контур_3899" data-name="Контур 3899" d="M577.5-799.055h27.957V-739H577.5Z" transform="translate(-449.879 961.516)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4305" data-name="Сгруппировать 4305" transform="translate(115.571 -1300.97)" clip-path="url(#clip-path-219)">
        <g id="Сгруппировать_4304" data-name="Сгруппировать 4304" transform="translate(-139.968 -163.428)" clip-path="url(#clip-path-2)">
          <path id="Контур_3902" data-name="Контур 3902" d="M642.131-798.6h8.652v7.34h-8.652Z" transform="translate(-503.05 961.142)" fill="#e8935b"/>
        </g>
      </g>
      <g id="Сгруппировать_4307" data-name="Сгруппировать 4307" transform="translate(114.52 -1313.044)" clip-path="url(#clip-path-221)">
        <g id="Сгруппировать_4306" data-name="Сгруппировать 4306" transform="translate(-138.916 -151.354)" clip-path="url(#clip-path-2)">
          <path id="Контур_3905" data-name="Контур 3905" d="M636.2-866.674h14.022v17.141H636.2Z" transform="translate(-498.173 1017.141)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4309" data-name="Сгруппировать 4309" transform="translate(111.591 -1314.301)" clip-path="url(#clip-path-223)">
        <g id="Сгруппировать_4308" data-name="Сгруппировать 4308" transform="translate(-135.988 -150.097)" clip-path="url(#clip-path-2)">
          <path id="Контур_3908" data-name="Контур 3908" d="M619.692-873.762h13.581v10.993H619.692Z" transform="translate(-484.591 1022.972)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_4311" data-name="Сгруппировать 4311" transform="translate(122.78 -1313.693)" clip-path="url(#clip-path-225)">
        <g id="Сгруппировать_4310" data-name="Сгруппировать 4310" transform="translate(-147.177 -150.705)" clip-path="url(#clip-path-2)">
          <path id="Контур_3911" data-name="Контур 3911" d="M682.774-870.334h6.385v10.107h-6.385Z" transform="translate(-536.484 1020.152)" fill="#15355f"/>
        </g>
      </g>
      <g id="Сгруппировать_4313" data-name="Сгруппировать 4313" transform="translate(121.754 -1301.514)" clip-path="url(#clip-path-227)">
        <g id="Сгруппировать_4312" data-name="Сгруппировать 4312" transform="translate(-146.15 -162.884)" clip-path="url(#clip-path-2)">
          <path id="Контур_3914" data-name="Контур 3914" d="M676.987-801.669h5.063v2.862h-5.063Z" transform="translate(-531.723 963.666)" fill="#ebf3f9"/>
        </g>
      </g>
      <g id="Сгруппировать_4315" data-name="Сгруппировать 4315" transform="translate(103.195 -1291.675)" clip-path="url(#clip-path-229)">
        <g id="Сгруппировать_4314" data-name="Сгруппировать 4314" transform="translate(-127.592 -172.723)" clip-path="url(#clip-path-2)">
          <path id="Контур_3917" data-name="Контур 3917" d="M572.356-746.2h29.916V-712.2H572.356Z" transform="translate(-445.651 918.032)" fill="#afd0ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4317" data-name="Сгруппировать 4317" transform="translate(105.683 -1273.153)" clip-path="url(#clip-path-231)">
        <g id="Сгруппировать_4316" data-name="Сгруппировать 4316" transform="translate(-130.08 -191.245)" clip-path="url(#clip-path-2)">
          <path id="Контур_3920" data-name="Контур 3920" d="M586.382-641.77h16.213V-627H586.382Z" transform="translate(-457.189 832.129)" fill="#f1ccaa"/>
        </g>
      </g>
      <g id="Сгруппировать_4319" data-name="Сгруппировать 4319" transform="translate(112.918 -1260.41)" clip-path="url(#clip-path-233)">
        <g id="Сгруппировать_4318" data-name="Сгруппировать 4318" transform="translate(-137.315 -203.988)" clip-path="url(#clip-path-2)">
          <path id="Контур_3923" data-name="Контур 3923" d="M627.171-569.93H648.7v93.744H627.171Z" transform="translate(-490.744 773.031)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_4321" data-name="Сгруппировать 4321" transform="translate(92.8 -1260.825)" clip-path="url(#clip-path-235)">
        <g id="Сгруппировать_4320" data-name="Сгруппировать 4320" transform="translate(-117.197 -203.573)" clip-path="url(#clip-path-2)">
          <path id="Контур_3926" data-name="Контур 3926" d="M513.751-572.27H549.16v89.8H513.751Z" transform="translate(-397.441 774.956)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_4323" data-name="Сгруппировать 4323" transform="translate(121.956 -1301.471)" clip-path="url(#clip-path-237)">
        <g id="Сгруппировать_4322" data-name="Сгруппировать 4322" transform="translate(-146.353 -162.927)" clip-path="url(#clip-path-2)">
          <path id="Контур_3929" data-name="Контур 3929" d="M678.128-801.43h4.949v2.777h-4.949Z" transform="translate(-532.662 963.47)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4325" data-name="Сгруппировать 4325" transform="translate(107.984 -1262.56)" clip-path="url(#clip-path-239)">
        <g id="Сгруппировать_4324" data-name="Сгруппировать 4324" transform="translate(-132.381 -201.838)" clip-path="url(#clip-path-2)">
          <path id="Контур_3932" data-name="Контур 3932" d="M599.357-582.05h22.667v6.473H599.357Z" transform="translate(-467.863 783.001)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4327" data-name="Сгруппировать 4327" transform="translate(122.336 -1261.797)" clip-path="url(#clip-path-241)">
        <g id="Сгруппировать_4326" data-name="Сгруппировать 4326" transform="translate(-146.733 -202.601)" clip-path="url(#clip-path-2)">
          <path id="Контур_3935" data-name="Контур 3935" d="M680.273-577.75h5.664v6.176h-5.664Z" transform="translate(-534.427 779.464)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4329" data-name="Сгруппировать 4329" transform="translate(94.549 -1278.131)" clip-path="url(#clip-path-243)">
        <g id="Сгруппировать_4328" data-name="Сгруппировать 4328" transform="translate(-118.946 -186.267)" clip-path="url(#clip-path-2)">
          <path id="Контур_3938" data-name="Контур 3938" d="M523.609-669.84H554.35v31.371H523.609Z" transform="translate(-405.55 855.22)" fill="#b5453b"/>
        </g>
      </g>
      <g id="Сгруппировать_4331" data-name="Сгруппировать 4331" transform="translate(110.498 -1259.999)" clip-path="url(#clip-path-245)">
        <g id="Сгруппировать_4330" data-name="Сгруппировать 4330" transform="translate(-134.894 -204.399)" clip-path="url(#clip-path-2)">
          <path id="Контур_3941" data-name="Контур 3941" d="M613.526-567.61h7.519v7.108h-7.519Z" transform="translate(-479.519 771.122)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4333" data-name="Сгруппировать 4333" transform="translate(93.674 -1291.675)" clip-path="url(#clip-path-247)">
        <g id="Сгруппировать_4332" data-name="Сгруппировать 4332" transform="translate(-118.071 -172.723)" clip-path="url(#clip-path-2)">
          <path id="Контур_3944" data-name="Контур 3944" d="M518.677-746.2h20.83v36.053h-20.83Z" transform="translate(-401.493 918.032)" fill="#afd0ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4335" data-name="Сгруппировать 4335" transform="translate(180.517 -1204.582)" clip-path="url(#clip-path-249)">
        <g id="Сгруппировать_4334" data-name="Сгруппировать 4334" transform="translate(-204.913 -259.817)" clip-path="url(#clip-path-2)">
          <path id="Контур_3947" data-name="Контур 3947" d="M1008.289-255.17h5.623v45.052h-5.623Z" transform="translate(-804.262 514.1)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4337" data-name="Сгруппировать 4337" transform="translate(163.769 -1204.582)" clip-path="url(#clip-path-251)">
        <g id="Сгруппировать_4336" data-name="Сгруппировать 4336" transform="translate(-188.166 -259.817)" clip-path="url(#clip-path-2)">
          <path id="Контур_3950" data-name="Контур 3950" d="M913.869-255.17h5.623v45.052h-5.623Z" transform="translate(-726.59 514.1)" fill="#94c0f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4339" data-name="Сгруппировать 4339" transform="translate(207.541 -1204.582)" clip-path="url(#clip-path-253)">
        <g id="Сгруппировать_4338" data-name="Сгруппировать 4338" transform="translate(-231.938 -259.817)" clip-path="url(#clip-path-2)">
          <path id="Контур_3953" data-name="Контур 3953" d="M1160.65-255.17h5.625v45.052h-5.625Z" transform="translate(-929.599 514.1)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4341" data-name="Сгруппировать 4341" transform="translate(162.569 -1245.079)" clip-path="url(#clip-path-255)">
        <g id="Сгруппировать_4340" data-name="Сгруппировать 4340" transform="translate(-186.965 -219.319)" clip-path="url(#clip-path-2)">
          <path id="Контур_3956" data-name="Контур 3956" d="M907.1-483.49h41.114V-442.4H907.1Z" transform="translate(-721.021 701.923)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4343" data-name="Сгруппировать 4343" transform="translate(162.569 -1206.87)" clip-path="url(#clip-path-257)">
        <g id="Сгруппировать_4342" data-name="Сгруппировать 4342" transform="translate(-186.965 -257.528)" clip-path="url(#clip-path-2)">
          <path id="Контур_3959" data-name="Контур 3959" d="M907.1-268.07h44.855v6.348H907.1Z" transform="translate(-721.021 524.712)" fill="#94c0f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4345" data-name="Сгруппировать 4345" transform="translate(177.936 -1206.87)" clip-path="url(#clip-path-259)">
        <g id="Сгруппировать_4344" data-name="Сгруппировать 4344" transform="translate(-202.333 -257.528)" clip-path="url(#clip-path-2)">
          <path id="Контур_3962" data-name="Контур 3962" d="M993.74-268.07h36.368v6.348H993.74Z" transform="translate(-792.294 524.712)" fill="#afd3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4347" data-name="Сгруппировать 4347" transform="translate(186.895 -1214.943)" clip-path="url(#clip-path-261)">
        <g id="Сгруппировать_4346" data-name="Сгруппировать 4346" transform="translate(-211.292 -249.455)" clip-path="url(#clip-path-2)">
          <path id="Контур_3965" data-name="Контур 3965" d="M1044.25-313.585h24.324v9.841H1044.25Z" transform="translate(-833.845 562.154)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4349" data-name="Сгруппировать 4349" transform="translate(196.905 -1186.797)" clip-path="url(#clip-path-263)">
        <g id="Сгруппировать_4348" data-name="Сгруппировать 4348" transform="translate(-221.301 -277.601)" clip-path="url(#clip-path-2)">
          <path id="Контур_3968" data-name="Контур 3968" d="M1100.684-154.9h15.2v26.974h-15.2Z" transform="translate(-880.269 431.615)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4351" data-name="Сгруппировать 4351" transform="translate(196.905 -1175.087)" clip-path="url(#clip-path-265)">
        <g id="Сгруппировать_4350" data-name="Сгруппировать 4350" transform="translate(-221.301 -289.311)" clip-path="url(#clip-path-2)">
          <path id="Контур_3971" data-name="Контур 3971" d="M1100.684-88.88h14.12v15.264h-14.12Z" transform="translate(-880.269 377.304)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4353" data-name="Сгруппировать 4353" transform="translate(235.365 -1188.391)" clip-path="url(#clip-path-267)">
        <g id="Сгруппировать_4352" data-name="Сгруппировать 4352" transform="translate(-259.762 -276.007)" clip-path="url(#clip-path-2)">
          <path id="Контур_3974" data-name="Контур 3974" d="M1317.52-163.89h21.705V-149.6H1317.52Z" transform="translate(-1058.645 439.01)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4355" data-name="Сгруппировать 4355" transform="translate(239.482 -1179.856)" clip-path="url(#clip-path-269)">
        <g id="Сгруппировать_4354" data-name="Сгруппировать 4354" transform="translate(-263.879 -284.542)" clip-path="url(#clip-path-2)">
          <path id="Контур_3977" data-name="Контур 3977" d="M1340.733-115.77h17.588v5.751h-17.588Z" transform="translate(-1077.741 399.425)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4357" data-name="Сгруппировать 4357" transform="translate(203.018 -1217.325)" clip-path="url(#clip-path-271)">
        <g id="Сгруппировать_4356" data-name="Сгруппировать 4356" transform="translate(-227.415 -247.073)" clip-path="url(#clip-path-2)">
          <path id="Контур_3980" data-name="Контур 3980" d="M1135.15-327.019h19.034v39.046H1135.15Z" transform="translate(-908.622 573.205)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4359" data-name="Сгруппировать 4359" transform="translate(170.005 -1231.886)" clip-path="url(#clip-path-273)">
        <g id="Сгруппировать_4358" data-name="Сгруппировать 4358" transform="translate(-194.402 -232.512)" clip-path="url(#clip-path-2)">
          <path id="Контур_3983" data-name="Контур 3983" d="M949.028-409.11H1024.1v49.579H949.028Z" transform="translate(-755.512 640.735)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_4361" data-name="Сгруппировать 4361" transform="translate(159.881 -1287.519)" clip-path="url(#clip-path-275)">
        <g id="Сгруппировать_4360" data-name="Сгруппировать 4360" transform="translate(-184.278 -176.879)" clip-path="url(#clip-path-2)">
          <path id="Контур_3986" data-name="Контур 3986" d="M891.946-722.765h40.714v44.924H891.946Z" transform="translate(-708.555 898.757)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4363" data-name="Сгруппировать 4363" transform="translate(177.858 -1270.515)" clip-path="url(#clip-path-277)">
        <g id="Сгруппировать_4362" data-name="Сгруппировать 4362" transform="translate(-202.254 -193.883)" clip-path="url(#clip-path-2)">
          <path id="Контур_3989" data-name="Контур 3989" d="M993.3-626.9h11.93v13.03H993.3Z" transform="translate(-791.93 819.896)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4365" data-name="Сгруппировать 4365" transform="translate(181.109 -1270.366)" clip-path="url(#clip-path-279)">
        <g id="Сгруппировать_4364" data-name="Сгруппировать 4364" transform="translate(-205.506 -194.032)" clip-path="url(#clip-path-2)">
          <path id="Контур_3992" data-name="Контур 3992" d="M1011.63-626.06h8.679v8.583h-8.679Z" transform="translate(-807.011 819.205)" fill="#ec965d"/>
        </g>
      </g>
      <g id="Сгруппировать_4367" data-name="Сгруппировать 4367" transform="translate(180.416 -1282.303)" clip-path="url(#clip-path-281)">
        <g id="Сгруппировать_4366" data-name="Сгруппировать 4366" transform="translate(-204.813 -182.095)" clip-path="url(#clip-path-2)">
          <path id="Контур_3995" data-name="Контур 3995" d="M1007.724-693.357h15.25v18.4h-15.25Z" transform="translate(-803.798 874.566)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4369" data-name="Сгруппировать 4369" transform="translate(177.705 -1282.821)" clip-path="url(#clip-path-283)">
        <g id="Сгруппировать_4368" data-name="Сгруппировать 4368" transform="translate(-202.102 -181.577)" clip-path="url(#clip-path-2)">
          <path id="Контур_3998" data-name="Контур 3998" d="M992.44-696.282h17.608v9.849H992.44Z" transform="translate(-791.225 876.971)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4371" data-name="Сгруппировать 4371" transform="translate(189.759 -1282.059)" clip-path="url(#clip-path-285)">
        <g id="Сгруппировать_4370" data-name="Сгруппировать 4370" transform="translate(-214.156 -182.339)" clip-path="url(#clip-path-2)">
          <path id="Контур_4001" data-name="Контур 4001" d="M1060.4-691.981h6.658v12.391H1060.4Z" transform="translate(-847.131 873.434)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4373" data-name="Сгруппировать 4373" transform="translate(169.75 -1264.315)" clip-path="url(#clip-path-287)">
        <g id="Сгруппировать_4372" data-name="Сгруппировать 4372" transform="translate(-194.146 -200.083)" clip-path="url(#clip-path-2)">
          <path id="Контур_4004" data-name="Контур 4004" d="M947.586-591.945h27.13v38.021h-27.13Z" transform="translate(-754.326 791.141)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4375" data-name="Сгруппировать 4375" transform="translate(203.018 -1209.84)" clip-path="url(#clip-path-289)">
        <g id="Сгруппировать_4374" data-name="Сгруппировать 4374" transform="translate(-227.415 -254.558)" clip-path="url(#clip-path-2)">
          <path id="Контур_4007" data-name="Контур 4007" d="M1135.15-284.82h14.332v31.562H1135.15Z" transform="translate(-908.622 538.491)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_4377" data-name="Сгруппировать 4377" transform="translate(187.189 -1263.557)" clip-path="url(#clip-path-291)">
        <g id="Сгруппировать_4376" data-name="Сгруппировать 4376" transform="translate(-211.586 -200.841)" clip-path="url(#clip-path-2)">
          <path id="Контур_4010" data-name="Контур 4010" d="M1045.91-587.67h17.6v31.191h-17.6Z" transform="translate(-835.211 787.624)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4379" data-name="Сгруппировать 4379" transform="translate(171.056 -1248.438)" clip-path="url(#clip-path-293)">
        <g id="Сгруппировать_4378" data-name="Сгруппировать 4378" transform="translate(-195.453 -215.96)" clip-path="url(#clip-path-2)">
          <path id="Контур_4013" data-name="Контур 4013" d="M954.95-502.43h19.865v22.144H954.95Z" transform="translate(-760.384 717.503)" fill="#ebf3ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4381" data-name="Сгруппировать 4381" transform="translate(182.429 -1232.781)" clip-path="url(#clip-path-295)">
        <g id="Сгруппировать_4380" data-name="Сгруппировать 4380" transform="translate(-206.826 -231.617)" clip-path="url(#clip-path-2)">
          <path id="Контур_4016" data-name="Контур 4016" d="M1019.07-414.16h12.017v5.33H1019.07Z" transform="translate(-813.131 644.89)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4383" data-name="Сгруппировать 4383" transform="translate(159.811 -1264.316)" clip-path="url(#clip-path-297)">
        <g id="Сгруппировать_4382" data-name="Сгруппировать 4382" transform="translate(-184.207 -200.082)" clip-path="url(#clip-path-2)">
          <path id="Контур_4019" data-name="Контур 4019" d="M891.551-591.95h25.325v38.559H891.551Z" transform="translate(-708.23 791.145)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4385" data-name="Сгруппировать 4385" transform="translate(189.428 -1231.05)" clip-path="url(#clip-path-299)">
        <g id="Сгруппировать_4384" data-name="Сгруппировать 4384" transform="translate(-213.825 -233.348)" clip-path="url(#clip-path-2)">
          <path id="Контур_4022" data-name="Контур 4022" d="M1058.53-404.4h4.037v3.67h-4.037Z" transform="translate(-845.592 636.861)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4387" data-name="Сгруппировать 4387" transform="translate(189.148 -1232.661)" clip-path="url(#clip-path-301)">
        <g id="Сгруппировать_4386" data-name="Сгруппировать 4386" transform="translate(-213.544 -231.737)" clip-path="url(#clip-path-2)">
          <path id="Контур_4025" data-name="Контур 4025" d="M1056.95-413.48h5.3v3.712h-5.3Z" transform="translate(-844.292 644.33)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4389" data-name="Сгруппировать 4389" transform="translate(191.037 -1247.76)" clip-path="url(#clip-path-303)">
        <g id="Сгруппировать_4388" data-name="Сгруппировать 4388" transform="translate(-215.433 -216.638)" clip-path="url(#clip-path-2)">
          <path id="Контур_4028" data-name="Контур 4028" d="M1067.6-498.61h38.042v22.631H1067.6Z" transform="translate(-853.054 714.361)" fill="#f89646"/>
        </g>
      </g>
      <g id="Сгруппировать_4391" data-name="Сгруппировать 4391" transform="translate(182.429 -1227.718)" clip-path="url(#clip-path-305)">
        <g id="Сгруппировать_4390" data-name="Сгруппировать 4390" transform="translate(-206.826 -236.68)" clip-path="url(#clip-path-2)">
          <path id="Контур_4031" data-name="Контур 4031" d="M1019.07-385.61h38.589v2.588H1019.07Z" transform="translate(-813.131 621.404)" fill="#ec7c2b"/>
        </g>
      </g>
      <g id="Сгруппировать_4393" data-name="Сгруппировать 4393" transform="translate(209.171 -1238.66)" clip-path="url(#clip-path-307)">
        <g id="Сгруппировать_4392" data-name="Сгруппировать 4392" transform="translate(-233.567 -225.738)" clip-path="url(#clip-path-2)">
          <path id="Контур_4034" data-name="Контур 4034" d="M1169.839-447.3h4.537v4.429h-4.537Z" transform="translate(-937.158 672.152)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4395" data-name="Сгруппировать 4395" transform="translate(332.496 -1221.128)" clip-path="url(#clip-path-309)">
        <g id="Сгруппировать_4394" data-name="Сгруппировать 4394" transform="translate(-356.893 -243.27)" clip-path="url(#clip-path-2)">
          <path id="Контур_4037" data-name="Контур 4037" d="M1865.14-348.46H1872v62.372h-6.864Z" transform="translate(-1509.134 590.843)" fill="#78acea"/>
        </g>
      </g>
      <g id="Сгруппировать_4397" data-name="Сгруппировать 4397" transform="translate(181.634 -1221.128)" clip-path="url(#clip-path-311)">
        <g id="Сгруппировать_4396" data-name="Сгруппировать 4396" transform="translate(-206.031 -243.27)" clip-path="url(#clip-path-2)">
          <path id="Контур_4040" data-name="Контур 4040" d="M1014.589-348.46h6.864v62.372h-6.864Z" transform="translate(-809.445 590.843)" fill="#78acea"/>
        </g>
      </g>
      <g id="Сгруппировать_4399" data-name="Сгруппировать 4399" transform="translate(176.215 -1226.714)" clip-path="url(#clip-path-313)">
        <g id="Сгруппировать_4398" data-name="Сгруппировать 4398" transform="translate(-200.612 -237.684)" clip-path="url(#clip-path-2)">
          <path id="Контур_4043" data-name="Контур 4043" d="M984.04-379.95h169.2v8.918H984.04Z" transform="translate(-784.315 616.747)" fill="#78acea"/>
        </g>
      </g>
      <g id="Сгруппировать_4401" data-name="Сгруппировать 4401" transform="translate(143.169 -1350.142)" clip-path="url(#clip-path-315)">
        <g id="Сгруппировать_4400" data-name="Сгруппировать 4400" transform="translate(-167.566 -114.256)" clip-path="url(#clip-path-2)">
          <path id="Контур_4046" data-name="Контур 4046" d="M797.727-1075.832h55.446v52.282H797.727Z" transform="translate(-631.047 1189.201)" fill="#dfecfa"/>
        </g>
      </g>
      <g id="Сгруппировать_4403" data-name="Сгруппировать 4403" transform="translate(439.31 -1166.106)" clip-path="url(#clip-path-317)">
        <g id="Сгруппировать_4402" data-name="Сгруппировать 4402" transform="translate(-463.707 -298.292)" clip-path="url(#clip-path-2)">
          <path id="Контур_4049" data-name="Контур 4049" d="M2467.35-38.248H2477.9v7.362H2467.35Z" transform="translate(-2004.53 335.653)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4405" data-name="Сгруппировать 4405" transform="translate(403.71 -1169.665)" clip-path="url(#clip-path-319)">
        <g id="Сгруппировать_4404" data-name="Сгруппировать 4404" transform="translate(-428.107 -294.733)" clip-path="url(#clip-path-2)">
          <path id="Контур_4052" data-name="Контур 4052" d="M2266.64-58.312h19.717v10.8H2266.64Z" transform="translate(-1839.42 352.158)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4407" data-name="Сгруппировать 4407" transform="translate(413.002 -1246.8)" clip-path="url(#clip-path-321)">
        <g id="Сгруппировать_4406" data-name="Сгруппировать 4406" transform="translate(-437.398 -217.598)" clip-path="url(#clip-path-2)">
          <path id="Контур_4055" data-name="Контур 4055" d="M2319.026-493.194h30.623v85.147h-30.623Z" transform="translate(-1882.514 709.906)" fill="#203e79"/>
        </g>
      </g>
      <g id="Сгруппировать_4409" data-name="Сгруппировать 4409" transform="translate(419.34 -1246.8)" clip-path="url(#clip-path-323)">
        <g id="Сгруппировать_4408" data-name="Сгруппировать 4408" transform="translate(-443.737 -217.598)" clip-path="url(#clip-path-2)">
          <path id="Контур_4058" data-name="Контур 4058" d="M2354.76-493.194h24.285v45.9H2354.76Z" transform="translate(-1911.91 709.906)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4411" data-name="Сгруппировать 4411" transform="translate(428.554 -1245.764)" clip-path="url(#clip-path-325)">
        <g id="Сгруппировать_4410" data-name="Сгруппировать 4410" transform="translate(-452.951 -218.634)" clip-path="url(#clip-path-2)">
          <path id="Контур_4061" data-name="Контур 4061" d="M2406.71-487.353h24.972v84.122H2406.71Z" transform="translate(-1954.646 705.101)" fill="#203e79"/>
        </g>
      </g>
      <g id="Сгруппировать_4413" data-name="Сгруппировать 4413" transform="translate(420.335 -1293.06)" clip-path="url(#clip-path-327)">
        <g id="Сгруппировать_4412" data-name="Сгруппировать 4412" transform="translate(-444.732 -171.338)" clip-path="url(#clip-path-2)">
          <path id="Контур_4064" data-name="Контур 4064" d="M2360.373-754h33.943v54.571h-33.943Z" transform="translate(-1916.528 924.456)" fill="#afd0ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4415" data-name="Сгруппировать 4415" transform="translate(414.67 -1293.059)" clip-path="url(#clip-path-329)">
        <g id="Сгруппировать_4414" data-name="Сгруппировать 4414" transform="translate(-439.066 -171.339)" clip-path="url(#clip-path-2)">
          <path id="Контур_4067" data-name="Контур 4067" d="M2328.429-754h19.759v34.508h-19.759Z" transform="translate(-1890.25 924.455)" fill="#afd0ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4417" data-name="Сгруппировать 4417" transform="translate(432.524 -1300.496)" clip-path="url(#clip-path-331)">
        <g id="Сгруппировать_4416" data-name="Сгруппировать 4416" transform="translate(-456.92 -163.902)" clip-path="url(#clip-path-2)">
          <path id="Контур_4070" data-name="Контур 4070" d="M2429.09-795.93h12.264v12.509H2429.09Z" transform="translate(-1973.057 958.945)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4419" data-name="Сгруппировать 4419" transform="translate(432.524 -1300.496)" clip-path="url(#clip-path-333)">
        <g id="Сгруппировать_4418" data-name="Сгруппировать 4418" transform="translate(-456.92 -163.902)" clip-path="url(#clip-path-2)">
          <path id="Контур_4073" data-name="Контур 4073" d="M2429.09-795.93h11.69v8.3h-11.69Z" transform="translate(-1973.057 958.945)" fill="#e1915a"/>
        </g>
      </g>
      <g id="Сгруппировать_4421" data-name="Сгруппировать 4421" transform="translate(430.382 -1313.622)" clip-path="url(#clip-path-335)">
        <g id="Сгруппировать_4420" data-name="Сгруппировать 4420" transform="translate(-454.778 -150.776)" clip-path="url(#clip-path-2)">
          <path id="Контур_4076" data-name="Контур 4076" d="M2417.014-869.933h15.671v18.272h-15.671Z" transform="translate(-1963.122 1019.822)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4423" data-name="Сгруппировать 4423" transform="translate(418.77 -1279.623)" clip-path="url(#clip-path-337)">
        <g id="Сгруппировать_4422" data-name="Сгруппировать 4422" transform="translate(-443.167 -184.775)" clip-path="url(#clip-path-2)">
          <path id="Контур_4079" data-name="Контур 4079" d="M2351.547-678.25h6.839v13.324h-6.839Z" transform="translate(-1909.267 862.138)" fill="#97b9f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4425" data-name="Сгруппировать 4425" transform="translate(429.267 -1317.189)" clip-path="url(#clip-path-339)">
        <g id="Сгруппировать_4424" data-name="Сгруппировать 4424" transform="translate(-453.664 -147.209)" clip-path="url(#clip-path-2)">
          <path id="Контур_4082" data-name="Контур 4082" d="M2410.731-890.042h19.492v19.173h-19.492Z" transform="translate(-1957.954 1036.365)" fill="#223861"/>
        </g>
      </g>
      <g id="Сгруппировать_4427" data-name="Сгруппировать 4427" transform="translate(421.138 -1264.1)" clip-path="url(#clip-path-341)">
        <g id="Сгруппировать_4426" data-name="Сгруппировать 4426" transform="translate(-445.535 -200.298)" clip-path="url(#clip-path-2)">
          <path id="Контур_4085" data-name="Контур 4085" d="M2364.9-590.73h24.913v12.7H2364.9Z" transform="translate(-1920.252 790.141)" fill="#97b9f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4429" data-name="Сгруппировать 4429" transform="translate(439.345 -1268.479)" clip-path="url(#clip-path-343)">
        <g id="Сгруппировать_4428" data-name="Сгруппировать 4428" transform="translate(-463.742 -195.919)" clip-path="url(#clip-path-2)">
          <path id="Контур_4088" data-name="Контур 4088" d="M2467.55-615.418h9.817v8.634h-9.817Z" transform="translate(-2004.695 810.451)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4431" data-name="Сгруппировать 4431" transform="translate(417.605 -1268.123)" clip-path="url(#clip-path-345)">
        <g id="Сгруппировать_4430" data-name="Сгруппировать 4430" transform="translate(-442.001 -196.275)" clip-path="url(#clip-path-2)">
          <path id="Контур_4091" data-name="Контур 4091" d="M2344.977-613.413H2371v9.713h-26.024Z" transform="translate(-1903.863 808.801)" fill="#afd0ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4433" data-name="Сгруппировать 4433" transform="translate(438.551 -1292.957)" clip-path="url(#clip-path-347)">
        <g id="Сгруппировать_4432" data-name="Сгруппировать 4432" transform="translate(-462.948 -171.441)" clip-path="url(#clip-path-2)">
          <path id="Контур_4094" data-name="Контур 4094" d="M2463.073-753.427h20.008V-723.1h-20.008Z" transform="translate(-2001.012 923.981)" fill="#afd0ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4435" data-name="Сгруппировать 4435" transform="translate(430.539 -1300.735)" clip-path="url(#clip-path-349)">
        <g id="Сгруппировать_4434" data-name="Сгруппировать 4434" transform="translate(-454.936 -163.663)" clip-path="url(#clip-path-2)">
          <path id="Контур_4097" data-name="Контур 4097" d="M2417.9-797.276h4.54v5.258h-4.54Z" transform="translate(-1963.851 960.052)" fill="#e1915a"/>
        </g>
      </g>
      <g id="Сгруппировать_4437" data-name="Сгруппировать 4437" transform="translate(427.476 -1300.519)" clip-path="url(#clip-path-351)">
        <g id="Сгруппировать_4436" data-name="Сгруппировать 4436" transform="translate(-451.873 -163.879)" clip-path="url(#clip-path-2)">
          <path id="Контур_4100" data-name="Контур 4100" d="M2400.631-796.06h7.1v7.748h-7.1Z" transform="translate(-1949.645 959.052)" fill="#ffb17c"/>
        </g>
      </g>
      <g id="Сгруппировать_4439" data-name="Сгруппировать 4439" transform="translate(428.498 -1295.56)" clip-path="url(#clip-path-353)">
        <g id="Сгруппировать_4438" data-name="Сгруппировать 4438" transform="translate(-452.895 -168.838)" clip-path="url(#clip-path-2)">
          <path id="Контур_4103" data-name="Контур 4103" d="M2406.393-768.1h15.731v32.931h-15.731Z" transform="translate(-1954.385 936.054)" fill="#afd0ff"/>
        </g>
      </g>
      <g id="Сгруппировать_4441" data-name="Сгруппировать 4441" transform="translate(428.552 -1290.012)" clip-path="url(#clip-path-355)">
        <g id="Сгруппировать_4440" data-name="Сгруппировать 4440" transform="translate(-452.949 -174.386)" clip-path="url(#clip-path-2)">
          <path id="Контур_4106" data-name="Контур 4106" d="M2406.7-736.82h24.1v27.382h-24.1Z" transform="translate(-1954.638 910.32)" fill="#97b9f4"/>
        </g>
      </g>
      <g id="Сгруппировать_4443" data-name="Сгруппировать 4443" transform="translate(251.122 -1247.571)" clip-path="url(#clip-path-357)">
        <g id="Сгруппировать_4442" data-name="Сгруппировать 4442" transform="translate(-275.519 -216.827)" clip-path="url(#clip-path-2)">
          <path id="Контур_4109" data-name="Контур 4109" d="M1406.36-497.54H1444.4v22.631H1406.36Z" transform="translate(-1131.728 713.481)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_4445" data-name="Сгруппировать 4445" transform="translate(259.184 -1227.526)" clip-path="url(#clip-path-359)">
        <g id="Сгруппировать_4444" data-name="Сгруппировать 4444" transform="translate(-283.581 -236.872)" clip-path="url(#clip-path-2)">
          <path id="Контур_4112" data-name="Контур 4112" d="M1451.81-384.53H1490.4v2.586H1451.81Z" transform="translate(-1169.116 620.515)" fill="#2b468a"/>
        </g>
      </g>
      <g id="Сгруппировать_4447" data-name="Сгруппировать 4447" transform="translate(266.494 -1238.47)" clip-path="url(#clip-path-361)">
        <g id="Сгруппировать_4446" data-name="Сгруппировать 4446" transform="translate(-290.891 -225.928)" clip-path="url(#clip-path-2)">
          <path id="Контур_4115" data-name="Контур 4115" d="M1493.024-446.23h4.537v4.429h-4.537Z" transform="translate(-1203.02 671.271)" fill="#fff"/>
        </g>
      </g>
      <g id="Сгруппировать_4449" data-name="Сгруппировать 4449" transform="translate(0.96 -1161.576)" clip-path="url(#clip-path-363)">
        <g id="Сгруппировать_4448" data-name="Сгруппировать 4448" transform="translate(-25.357 -302.822)" clip-path="url(#clip-path-2)">
          <path id="Контур_4118" data-name="Контур 4118" d="M-4.04-12.71H510.337v2.985H-4.04Z" transform="translate(28.51 314.645)" fill="#2d3552"/>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
name: "MeetingRoomImage"
}
</script>

<style scoped>

</style>

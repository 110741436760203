<template>
  <b-modal
      id="modal-add-edit-meeting-room"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ isEdit ? $t("MANAGEMENT.EDIT_MEET_ROOM_TITLE") : $t("MANAGEMENT.ADD_MEET_ROOM_TITLE") }} </h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label-for="name-input"
          :invalid-feedback="$t('MANAGEMENT.NAME_MEET_ROOM_INVALID_FEEDBACK')"
          :state="nameState"
      >
        <template slot="label">{{ $t("MANAGEMENT.NAME_MEET_ROOM_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-input
            id="name-input"
            :placeholder="$t('MANAGEMENT.NAME_MEET_ROOM_PLACEHOLDER')"
            v-model="form.name"
            :state="nameState"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          v-if="isEdit"
          label-for="status-input"
          :invalid-feedback="$t('MANAGEMENT.MEET_ROOM_STATUS_INVALID_FEEDBACK')"
          :state="statusState"
      >
        <template slot="label">{{ $t("MANAGEMENT.MEET_ROOM_STATUS_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="status-input"
            v-model="form.status"
            :state="statusState"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="item.value" v-for="item in statusesList" :key="item.value">{{ item.name }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label-for="input-area" :state="areaState">
        <template slot="label">{{ $t("MANAGEMENT.SQUARE_INPUT_TITLE") }}</template>
        <b-input-group>
          <template #append>
            <b-input-group-text>м<sup>2</sup></b-input-group-text>
          </template>
          <b-form-input
              type="number"
              step="0.10" min="0.00" max="1000.00"
              id="input-area"
              :placeholder="$t('MANAGEMENT.SQUARE_PLACEHOLDER')"
              v-model="form.properties.area"
              :state="areaState"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group label-for="amount-input" :state="amountState">
        <template slot="label">{{ $t("MANAGEMENT.CAPACITY_INPUT_TITLE") }}</template>
        <b-input-group>
          <template #append>
            <b-input-group-text>{{ $t("MANAGEMENT.PEOPLE_TITLE") }}</b-input-group-text>
          </template>
          <b-form-input
              type="number"
              step="1" min="0" max="1000"
              id="amount-input"
              :placeholder="$t('MANAGEMENT.CAPACITY_INPUT_PLACEHOLDER')"
              v-model="form.properties.amountOfSeats"
              :state="amountState"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group
          v-if="isEdit"
          label-for="accepted-groups-input">
        <template slot="label">{{ $t("MANAGEMENT.RESOURCE_ACCESS_TITLE") }}</template>
        <DropdownSearch
            :options="groupsList"
            v-on:selectedGroup="onSearchGroups"
            v-on:filter="getDropdownGroupsValues"
            :disabled="false"
            name="dropdownGroupSearch"
            :placeholder="$t('MANAGEMENT.RESOURCE_ACCESS_PLACEHOLDER')">
        </DropdownSearch>
        <div class="col-12 p-0">
          <div class="selected-groups mr-4">
            <p class="mt-2 mb-0 p-0">
              {{ addedResourceGroups.length > 0 ? $t("MANAGEMENT.RESOURCE_ACCESS_LIST_TITLE") :
                $t("MANAGEMENT.NO_RESOURCE_YET_TITLE") }}</p>
            <div class="row selected-groups-item py-3"
                 v-for="(group, i) in addedResourceGroups" :key="i">
              <div class="col-11">
                <h6 class="text-left font-weight-bolder text-dark pt-2 m-0">{{ group.name }}</h6>
              </div>
              <div class="col-1 mb-1">
                <span class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      @click="onDeleteGroup(group)"><Trash/></span>
              </div>
            </div>
          </div>
        </div>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ isEdit ? $t("MANAGEMENT.SAVE_BUTTON") : $t("MANAGEMENT.ADD_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

import {
  GET_MEETING_ROOMS_LIST,
  POST_NEW_MEETING_ROOM,
  PUT_MEETING_ROOM,
  ACTIVATE_MEETING_ROOM,
  DEACTIVATE_MEETING_ROOM,
  SET_RESOURCE_ASSIGNED_GROUP,
  ADD_ASSIGN_GROUP,
  GET_RESOURCE_ASSIGNED_GROUP,
  REMOVE_ASSIGN_GROUP
} from "@/core/services/store/management/resources.module";

import DropdownSearch from "@/view/layout/extras/DropdownSearch";
import Trash from "@/view/layout/svg/Trash";
import {GET_GROUPS_LIST} from "@/core/services/store/management.module";

export default {
  name: "AddEditMeetingRoomModal",
  components: {
    DropdownSearch,
    Trash
  },
  props: {
    onPage: {
      type: String
    },
    form: {
      type: Object,
      default: ()=> {
        return {
          name: '',
          properties: {
            area: null,
            amountOfSeats: null
          }
        }
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nameState: null,
      statusState: null,
      statusesList: [
        { name: this.$t("MANAGEMENT.AV_STATUS_TYPE"), value: "active" },
        { name: this.$t("MANAGEMENT.UN_STATUS_TYPE"), value: "inactive"}
      ],
      areaState: null,
      amountState: null
    }
  },
  computed: {
    ...mapGetters([
      "groupsList",
      "addedResourceGroups"
    ]),
  },
  methods: {
    onSearchGroups(searchQuery) {
      this.$store.dispatch(ADD_ASSIGN_GROUP, { id: this.form.id, body: { groupId: searchQuery.id } })
          .then(() => this.$store.dispatch(GET_RESOURCE_ASSIGNED_GROUP, { id: this.form.id }));
    },
    getDropdownGroupsValues(searchQuery) {
      this.$store.dispatch(GET_GROUPS_LIST, { name: searchQuery });
    },
    onDeleteGroup(group) {
      this.$store.dispatch(REMOVE_ASSIGN_GROUP, { id: this.form.id, body: { groupId: group.id } })
          .then(() => this.$store.dispatch(GET_RESOURCE_ASSIGNED_GROUP, { id: this.form.id }))
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      if (this.isEdit) this.form.status !== null ? this.statusState = true : this.statusState = false;
      this.areaState = true;
      this.amountState = true;
      return valid;
    },
    resetModal() {
      if (this.onPage === 'resources') {
        let emptyForm = {
          name: '',
          properties: {
            area: null,
            amountOfSeats: null
          }
        };
        this.$emit('changeMeetingRoomForm', emptyForm);
      }
      this.$emit('changeIsEdit', false);
      this.nameState = null;
      this.statusState = null;
      this.areaState = null;
      this.amountState = null;
      this.$store.commit(SET_RESOURCE_ASSIGNED_GROUP, []);
      this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id });
      this.$bvModal.hide('modal-add-edit-meeting-room');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.isEdit) {
        let id = this.form.id;
        let status = this.form.status;
        let form = {
          name: this.form.name,
          properties: {
            area: null,
            amountOfSeats: null
          }
        }
        if (this.form.properties.area !== null) {
          form.properties.area = parseFloat(this.form.properties.area)
        }
        if (this.form.properties.amountOfSeats !== null) {
          form.properties.amountOfSeats = parseInt(this.form.properties.amountOfSeats, 10)
        }
        this.$store.dispatch(PUT_MEETING_ROOM, { form, id })
            .then(() => {
              if (status === 'active') this.$store.dispatch(ACTIVATE_MEETING_ROOM, { id })
              else this.$store.dispatch(DEACTIVATE_MEETING_ROOM, { id })
            })
      } else {
        let form = {
          name: this.form.name,
          properties: {
            area: null,
            amountOfSeats: null
          }
        }
        if (this.form.properties.area !== null) {
          form.properties.area = parseFloat(this.form.properties.area)
        }
        if (this.form.properties.amountOfSeats !== null) {
          form.properties.amountOfSeats = parseInt(this.form.properties.amountOfSeats, 10)
        }
        this.$store.dispatch(POST_NEW_MEETING_ROOM, { id: this.$route.params.id, body: form });
      }
      // this.$nextTick(() => {
        setTimeout(() => this.$store.dispatch(GET_MEETING_ROOMS_LIST, { id: this.$route.params.id }), 500)
        this.$bvModal.hide('modal-add-edit-meeting-room');
      // })
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="358.471" height="250" viewBox="0 0 358.471 250">
    <g id="Img" transform="translate(0 -0.714)">
      <path id="Контур_3533" data-name="Контур 3533" d="M348.871,223.489c-7.935,6.581-19.227,9.3-30.147,11.025l-1.838.28a344.984,344.984,0,0,1-34.511,3.157c-6.191.3-12.383.512-18.575.7l-4.853.143q-9.451.28-18.937.55-9.288.264-18.575.5-11.895.29-23.789.482-9.288.146-18.575.2-11.9.068-23.79-.046-9.294-.095-18.575-.326-11.9-.306-23.789-.9-8.069-.395-16.128-.948l-2.448-.173c-23.062-1.661-46.539-4.642-66.721-14.063l-.544-.258c-.27-.127-.541-.254-.811-.387a73.042,73.042,0,0,1-10.611-6.22c-5.967-4.254-10.894-9.448-13.5-15.608-4.08-9.662-1.923-20.524,2.737-29.972S16.551,153.653,22.6,144.756c1.773-2.606,3.454-5.31,5.019-8.083C37.4,119.286,42.246,99.167,32,82.412a74.529,74.529,0,0,0-4.377-6.189A58.661,58.661,0,0,1,20.862,66.1c-4.005-8.392-3.145-17.83-1.685-26.8,1.955-11.92,5.54-24.759,16.744-32.206C47.545-.635,64.044-.244,78.471,2.538c22.43,4.3,43.134,13.292,64.032,21.4S185.3,39.472,208.273,40.27a104.986,104.986,0,0,0,14.788-.538,93.818,93.818,0,0,0,19.266-4.056C251.371,32.7,259.6,28.219,265.7,21.994c13.084-13.308,37.672-14.315,55.4-6.278,16.916,7.669,27.856,23.9,27.048,40.11-.919,18.045-14.436,32.881-26.823,48.1-1.5,1.844-2.986,3.695-4.429,5.558q-1.209,1.56-2.372,3.128c-1.359,1.844-2.672,3.7-3.911,5.574-6.329,9.621-10.8,21.176-6.446,31.561,2.444,5.815,7.108,10.252,12.729,14.126,12.6,8.675,29.982,14.513,37.8,26.658C361.437,200.994,358.941,215.124,348.871,223.489Z" transform="translate(0 0)" fill="#ebf3f9"/>
      <path id="Контур_3534" data-name="Контур 3534" d="M340.761,307.033c0,14.035-61.181,40.141-138.249,40.141S62,322.686,62,308.651s63.445-12.3,140.512-12.3S340.761,293,340.761,307.033Z" transform="translate(-20.253 -96.459)" fill="#3f3d56"/>
      <path id="Контур_3535" data-name="Контур 3535" d="M340.761,307.033c0,14.035-61.181,40.141-138.249,40.141S62,322.686,62,308.651s63.445-12.3,140.512-12.3S340.761,293,340.761,307.033Z" transform="translate(-20.253 -96.459)" fill="#b9cad6"/>
      <ellipse id="Эллипс_47" data-name="Эллипс 47" cx="139.381" cy="25.25" rx="139.381" ry="25.25" transform="translate(41.747 187.421)" fill="#cfdfeb"/>
      <path id="Контур_3536" data-name="Контур 3536" d="M347,187v67.68l11.351,18.45.864,1.4H404l.9-87.534Z" transform="translate(-113.352 -60.853)" fill="#2f2e41"/>
      <path id="Контур_3537" data-name="Контур 3537" d="M347,187v67.725l11.419,18.462.7-86.187Z" transform="translate(-113.352 -60.853)" opacity="0.1"/>
      <path id="Контур_3538" data-name="Контур 3538" d="M158.907,184v67.68l-11.35,18.45-.865,1.4H101.9L101,184Z" transform="translate(-32.993 -59.873)" fill="#2f2e41"/>
      <path id="Контур_3539" data-name="Контур 3539" d="M181.12,184v67.725L169.7,270.187,169,184Z" transform="translate(-55.206 -59.873)" opacity="0.1"/>
      <path id="Контур_3540" data-name="Контур 3540" d="M335.074,175.467v4.04H92v-5.836L114.59,162h200.6Z" transform="translate(-30.053 -52.686)" fill="#2f2e41"/>
      <path id="Контур_3541" data-name="Контур 3541" d="M335.074,180.864v4.2H92V179Z" transform="translate(-30.053 -58.24)" opacity="0.1"/>
      <path id="Контур_3542" data-name="Контур 3542" d="M136.263,210.693H125.489L121,208h20.2Z" transform="translate(-39.526 -67.713)" opacity="0.1"/>
      <path id="Контур_3543" data-name="Контур 3543" d="M136.263,229.693H125.489L121,227h20.2Z" transform="translate(-39.526 -73.919)" opacity="0.1"/>
      <path id="Контур_3544" data-name="Контур 3544" d="M136.263,257.693H125.489L121,255h20.2Z" transform="translate(-39.526 -83.066)" opacity="0.1"/>
      <path id="Контур_3545" data-name="Контур 3545" d="M136.263,284.693H125.489L121,282h20.2Z" transform="translate(-39.526 -91.886)" opacity="0.1"/>
      <path id="Контур_3546" data-name="Контур 3546" d="M388.938,212.693h10.773L404.2,210H384Z" transform="translate(-125.439 -68.366)" opacity="0.1"/>
      <path id="Контур_3547" data-name="Контур 3547" d="M388.938,232.693h10.773L404.2,230H384Z" transform="translate(-125.439 -74.899)" opacity="0.1"/>
      <path id="Контур_3548" data-name="Контур 3548" d="M388.938,259.693h10.773L404.2,257H384Z" transform="translate(-125.439 -83.719)" opacity="0.1"/>
      <path id="Контур_3549" data-name="Контур 3549" d="M388.938,287.693h10.773L404.2,285H384Z" transform="translate(-125.439 -92.866)" opacity="0.1"/>
      <path id="Контур_3550" data-name="Контур 3550" d="M315.546,144.573l-1.5,9.507s-7.388,3.895-2.194,4.009,29.67,0,29.67,0,4.733,0-2.771-4.124l-1.5-9.965Z" transform="translate(-101.266 -46.806)" fill="#3f3d56"/>
      <path id="Контур_3551" data-name="Контур 3551" d="M310.8,158.117a20.481,20.481,0,0,1,3.239-2.147l1.5-9.6,21.7.021,1.5,9.46a19.669,19.669,0,0,1,3.468,2.285c.964-.22,2.017-1.039-3.468-4.081L337.249,144l-21.7.578-1.5,9.6S307.835,157.478,310.8,158.117Z" transform="translate(-101.266 -46.806)" opacity="0.1"/>
      <rect id="Прямоугольник_532" data-name="Прямоугольник 532" width="98.307" height="68.68" rx="10.695" transform="translate(175.067 30.533)" fill="#3f3d56"/>
      <rect id="Прямоугольник_533" data-name="Прямоугольник 533" width="92.247" height="51.174" rx="8" transform="translate(178.434 34.573)" fill="#ebf3f9"/>
      <circle id="Эллипс_48" data-name="Эллипс 48" cx="0.673" cy="0.673" r="0.673" transform="translate(223.547 31.88)" fill="#f2f2f2"/>
      <path id="Контур_3552" data-name="Контур 3552" d="M358.307,133v5.982a3.45,3.45,0,0,1-3.456,3.445H263.456A3.45,3.45,0,0,1,260,138.982V133Z" transform="translate(-84.933 -43.213)" fill="#3f3d56"/>
      <circle id="Эллипс_49" data-name="Эллипс 49" cx="2.02" cy="2.02" r="2.02" transform="translate(222.201 92.48)" fill="#f2f2f2"/>
      <path id="Контур_3553" data-name="Контур 3553" d="M297.947,173.216v1.171H236v-.937l.086-.234L237.612,169H296.68Z" transform="translate(-77.093 -54.973)" fill="#3f3d56"/>
      <path id="Контур_3554" data-name="Контур 3554" d="M355.487,170.938c-.113.479-.541.985-1.51,1.462-3.473,1.716-10.536-.457-10.536-.457S338,171.028,338,168.627a4.284,4.284,0,0,1,.477-.28c1.46-.764,6.3-2.646,14.887.079A3.578,3.578,0,0,1,355,169.483,1.673,1.673,0,0,1,355.487,170.938Z" transform="translate(-110.412 -54.32)" fill="#3f3d56"/>
      <path id="Контур_3555" data-name="Контур 3555" d="M355.487,170.17c-4.371,1.7-8.267,1.831-12.264-.995a8.68,8.68,0,0,0-5.223-1.75c1.5-.808,6.479-2.8,15.3.085a3.671,3.671,0,0,1,1.678,1.118A1.806,1.806,0,0,1,355.487,170.17Z" transform="translate(-110.412 -53.993)" opacity="0.1"/>
      <ellipse id="Эллипс_50" data-name="Эллипс 50" cx="1.347" cy="0.337" rx="1.347" ry="0.337" transform="translate(239.034 114.7)" fill="#f2f2f2"/>
      <path id="Контур_3556" data-name="Контур 3556" d="M297.947,174v1.347H236v-1.077l.086-.269Z" transform="translate(-77.093 -56.606)" opacity="0.1"/>
      <circle id="Эллипс_51" data-name="Эллипс 51" cx="12.457" cy="12.457" r="12.457" transform="translate(156.887 21.78)" fill="#ffb9b9"/>
      <path id="Контур_3557" data-name="Контур 3557" d="M237,58.606V77.873h20.2S254.594,61.817,255.9,57Z" transform="translate(-77.419 -18.387)" fill="#ffb9b9"/>
      <path id="Контур_3558" data-name="Контур 3558" d="M265.914,90.163S246.86,80.73,246.86,80.079s-1.938-4.879-3.23-4.879-12.272-3.578-18.408,1.626l-.646,4.229L201,96.669l6.459,23.747s4.2,4.554,2.907,7.807.968,19.843.968,19.843l50.7-1.627V124.32a51.8,51.8,0,0,1,2.584-6.831c.969-1.627,0-6.506,0-6.506l7.751-9.759S271.4,92.115,265.914,90.163Z" transform="translate(-65.66 -23.94)" fill="#d0cde1"/>
      <path id="Контур_3559" data-name="Контур 3559" d="M198.177,115.939,199.661,108s-8.57,36.615-7.581,40.827,8.24,10.693,9.229,11.017,10.218-27.866,10.218-27.866Z" transform="translate(-62.72 -35.046)" opacity="0.1"/>
      <path id="Контур_3560" data-name="Контур 3560" d="M202.264,107l-5.6.967s-8.57,36.407-7.581,40.6,8.24,10.632,9.229,10.954,10.218-27.708,10.218-27.708Z" transform="translate(-61.74 -34.72)" fill="#d0cde1"/>
      <path id="Контур_3561" data-name="Контур 3561" d="M295.662,120.351,298.34,115s3.78,36.644,1.26,40.212-12.488,4.9-12.488,4.9L287,133.484Z" transform="translate(-93.753 -37.333)" opacity="0.1"/>
      <path id="Контур_3562" data-name="Контур 3562" d="M297.245,109l4.1,4.2s3.78,36.514,1.26,40.068-12.488,4.884-12.488,4.884L290,131.619Z" transform="translate(-94.733 -35.373)" fill="#d0cde1"/>
      <path id="Контур_3563" data-name="Контур 3563" d="M201.337,190.933s-9.415-3.565-11.038,3.24,3.9,37.263,3.9,37.263,3.571,23.006,5.843,23.654,12.337.972,13.96-1.944-4.87-32.727-4.87-32.727l37.66-.648s-2.273,25.922-3.571,28.191-1.3,10.045,0,10.045,15.583-.648,16.557-2.592,6.493-45.688,6.493-45.688,4.545-15.23-.65-14.257S201.337,190.933,201.337,190.933Z" transform="translate(-62.066 -61.833)" fill="#3f3d56"/>
      <path id="Контур_3564" data-name="Контур 3564" d="M283.843,285.021s3.293-2.3,4.939,0,2.306,4.923-4.939,8.862S271,296.838,271,296.181v-9.519Z" transform="translate(-88.526 -92.539)" fill="#2f2e41"/>
      <path id="Контур_3565" data-name="Контур 3565" d="M203.011,284.021s-3.293-2.3-4.939,0-2.305,4.923,4.939,8.862,12.843,2.954,12.843,2.3v-9.519Z" transform="translate(-64.353 -92.213)" fill="#2f2e41"/>
      <path id="Контур_3566" data-name="Контур 3566" d="M244.938,27.006a10.4,10.4,0,0,0-3.6.511,13.919,13.919,0,0,0-2.565,1.352l-5.412,3.354a2.525,2.525,0,0,0-1.528,2.354l-.676,5.047a6.55,6.55,0,0,0,.244,3.788,10.473,10.473,0,0,0,2.137,2.389,8.759,8.759,0,0,1,2.32,4.491c.387,2.107.139,4.645,1.813,5.99a5.7,5.7,0,0,0,3.3.934,49.455,49.455,0,0,0,9.519-.365,3.8,3.8,0,0,0,1.438-.4,3.944,3.944,0,0,0,1.262-1.5c1.208-2.051,2.454-4.232,2.441-6.61-.007-1.176-.309-2.44.258-3.472a13.627,13.627,0,0,1,.974-1.26,6.945,6.945,0,0,0,.953-3.446c.365-3.836-.017-6.755-2.853-9.457A14.724,14.724,0,0,0,244.938,27.006Z" transform="translate(-75.459 -8.587)" fill="#2f2e41"/>
      <path id="Контур_3567" data-name="Контур 3567" d="M201.081,248,190,303.214h5.54l10.62-54.308Z" transform="translate(-62.066 -80.779)" fill="#2f2e41"/>
      <path id="Контур_3568" data-name="Контур 3568" d="M258,254.815l6.868,63.5h4.579L265.326,253Z" transform="translate(-84.279 -82.413)" fill="#2f2e41"/>
      <path id="Контур_3569" data-name="Контур 3569" d="M286,252.893l9.122,41.527h4.344L290.779,252Z" transform="translate(-93.426 -82.086)" fill="#2f2e41"/>
      <path id="Контур_3570" data-name="Контур 3570" d="M231.443,253,230,285.993h3.847L236.733,253Z" transform="translate(-75.133 -82.413)" fill="#2f2e41"/>
      <path id="Контур_3571" data-name="Контур 3571" d="M194.246,215.32s-7.212-48.362,13.071-49.718,49.13,0,49.13,0,16.677-1.356,11.719,49.718c0,0,4.507,14.915-36.058,13.56S194.246,215.32,194.246,215.32Z" transform="translate(-63.046 -53.666)" fill="#afafaf"/>
      <path id="Контур_3572" data-name="Контур 3572" d="M459.22,260.012c0,8.367-4.974,11.288-11.11,11.288S437,268.379,437,260.012,448.11,241,448.11,241,459.22,251.644,459.22,260.012Z" transform="translate(-351.15 -172.759)" fill="#93e3e0"/>
      <path id="Контур_3573" data-name="Контур 3573" d="M450.635,263l4.792-8.656-4.775,7.558.053-3.146,3.3-6.263-3.289,5.43.093-5.658,3.537-4.985-3.522,4.1L450.884,241l-.349,13.147-3.582-5.412,3.538,6.521-.335,6.319-.01-.168L446,255.689,450.133,262l-.042.79-.007.012,0,.065-.85,8.433h1.136l.137-.681,4.123-6.3L450.519,270Z" transform="translate(-354.09 -172.759)" fill="#1bc5bd"/>
      <path id="Контур_3574" data-name="Контур 3574" d="M455.823,303.527H442.455L439,284h19.527Z" transform="translate(-351.803 -186.806)" fill="#504f62"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DeskImage"
}
</script>

<style scoped>

</style>
